import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import HealthCheck from 'pages/Public/HealthCheck';
import UnexpectedErrorPage from 'pages/System/UnexpectedError';
import { Router } from 'router';
import { ROUTES } from 'router/variables';
import { NavigationService } from 'services/Navigation';
import { store } from 'store';

import { ReactSuspenseFallback } from './ReactSuspenseFallback';
import { Wrapper } from './Wrapper';

import styles from './ApplicationRootContainer.module.scss';

export const ApplicationRootContainer = () => {
  if (window.location.pathname === ROUTES.Public.HealthCheck) {
    return (
      <Router history={NavigationService.history}>
        <HealthCheck />
      </Router>
    );
  }

  return (
    <Provider store={store}>
      <Router history={NavigationService.history}>
        <Suspense fallback={<ReactSuspenseFallback />}>
          <div className={styles.container} data-testid="application-root-container">
            <Sentry.ErrorBoundary fallback={<UnexpectedErrorPage />}>
              <Wrapper />
            </Sentry.ErrorBoundary>
          </div>
        </Suspense>
      </Router>
    </Provider>
  );
};
