import { combineReducers, Reducer } from 'redux';

import { AllActions } from '../types';

import { apiRequestsReducer } from './api-requests/reducer';
import { globalReducer } from './global/reducer';
import { propertyReducer } from './property/reducer';
import { supportReducer } from './support/reducer';
import { userReducer } from './user/reducer';
import { UserActionTypes } from './user/types';

// Combine all application reducers to delegate them to
// the root reducer that will be exported to the store
const allReducers = combineReducers({
  apiRequests: apiRequestsReducer,
  global: globalReducer,
  property: propertyReducer,
  support: supportReducer,
  user: userReducer,
});

type AppState = ReturnType<typeof allReducers>;

export const rootReducer: Reducer<AppState, AllActions> = (state, action): AppState => {
  // Handle logout on the global level to reset the whole Redux store and as a
  // result application state so we don't need to reset each reducer separately.
  if ((action.type as any) === UserActionTypes.LOG_OUT__SUCCESS) {
    // Reassign the reference of a local variable called `state` to `undefined` before
    // passing it to another reducer. Reducers are supposed to return the initial state
    // when they are called with `undefined` as the first argument, no matter the action.
    // Please note that some data still should stay in the store to not to face unexpected
    // behaviour.
    state = undefined;
  }

  // Delegate handling the action to the reducer generated by `combineReducers()`
  return allReducers(state, action);
};
