/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EmailVerificationStatus {
  EmailOrSecretIsInvalid = 0,
  SecretIsExpired = 1,
  HomeOwnerIsInactive = 2,
  ExistingHomeOwnerShouldFinishRegistration = 3,
  NewHomeOwnerIsVerified = 4,
  HomeOwnerIsAlreadyVerified = 5,
}
