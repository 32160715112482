import React from 'react';
import merge from 'classnames';

import { addDefault } from './helpers';
import { SvgIconContainer } from './SvgIconContainer';
import { Props } from './types';

export const IconPillPrivacy = (props: Props) => {
  const { onClick, classes } = addDefault(props);

  return (
    <SvgIconContainer className={classes.container} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="14"
        viewBox="0 0 29 14"
        fill="none"
        className={merge(classes.icon)}
      >
        <title>California Consumer Privacy Act (CCPA) Opt-Out Icon</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.21203 12.5615H13.7326L16.7052 1.43823H7.21203C4.14354 1.43823 1.65039 3.93138 1.65039 6.99988C1.65039 10.0684 4.14354 12.5615 7.21203 12.5615Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7877 0.287598H7.21233C3.4726 0.287598 0.5 3.2602 0.5 6.99993C0.5 10.7397 3.4726 13.7123 7.21233 13.7123H21.7877C25.5274 13.7123 28.5 10.7397 28.5 6.99993C28.5 3.2602 25.4315 0.287598 21.7877 0.287598ZM1.65068 6.99993C1.65068 3.93143 4.14384 1.43828 7.21233 1.43828H16.7055L13.7329 12.5616H7.21233C4.14384 12.5616 1.65068 10.0684 1.65068 6.99993Z"
          fill="#2774AE"
        />
        <path
          d="M23.7054 4.12333C23.8971 4.31511 23.8971 4.69867 23.7054 4.89045L21.6917 7.00004L23.8013 9.10963C23.993 9.30141 23.993 9.68497 23.8013 9.87675C23.6095 10.0685 23.2259 10.0685 23.0341 9.87675L20.9245 7.76716L18.815 9.87675C18.6232 10.0685 18.2396 10.0685 18.0478 9.87675C17.8561 9.68497 17.8561 9.30141 18.0478 9.10963L20.0615 7.00004L17.9519 4.89045C17.7602 4.69867 17.7602 4.31511 17.9519 4.12333C18.1437 3.93155 18.5273 3.93155 18.7191 4.12333L20.8287 6.23292L22.9382 4.12333C23.13 3.93155 23.5136 3.93155 23.7054 4.12333Z"
          fill="white"
        />
        <path
          d="M12.2947 4.21903C12.4865 4.41081 12.5824 4.79437 12.3906 4.98615L8.36321 9.68478C8.26732 9.78067 8.17143 9.87656 8.07554 9.87656C7.88376 9.97246 7.59608 9.97246 7.4043 9.78067L5.29471 7.67109C5.10293 7.4793 5.10293 7.09574 5.29471 6.90396C5.4865 6.71218 5.87006 6.71218 6.06184 6.90396L7.78787 8.5341L11.4317 4.21903C11.6235 4.02725 12.007 4.02725 12.2947 4.21903Z"
          fill="#2774AE"
        />
      </svg>
    </SvgIconContainer>
  );
};
