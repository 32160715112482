/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PropertyAddressCheckStatus {
  AddressIsVacant = 0,
  PropertyIsAlreadyRegistered = 1,
  PropertyIsAlreadyRegisteredForCurrentHomeOwnerAndBeingProcessed = 2,
  PropertyIsAlreadyRegisteredForCurrentHomeOwnerAndReadyForMonitoring = 3,
}
