import React, { MouseEvent } from 'react';
import { ProgressSpinner } from '@gafe/common-ui';
import merge from 'classnames';

import { Props } from './types';

import styles from './Button.module.scss';

// TODO: think about adding something like "link" button
// TODO: where this button is just a simple clickable text

export const Button = (props: Props) => {
  const {
    size,
    color,
    onClick,
    children,
    className,
    colorSchema,
    loading = false,
    disabled = false,
    loadingMessage = 'Loading',
    ...buttonProps
  } = props;

  return (
    <button
      type="button"
      data-testid={Button._jestTestId}
      {...buttonProps}
      disabled={disabled}
      className={merge(
        styles.button,
        styles[size],
        styles[color],
        styles[colorSchema],
        { [styles.loading]: loading },
        className,
      )}
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        if (loading || disabled) return;
        onClick && onClick(event);
      }}
    >
      {(() => {
        if (loading) {
          return (
            <>
              <ProgressSpinner size="small" classes={{ container: styles.spinner }} />
              <span>{loadingMessage}...</span>
            </>
          );
        }

        if (typeof children === 'string') {
          return <span>{children}</span>;
        }

        return children;
      })()}
    </button>
  );
};

Button._jestTestId = 'button';
