import React, { ImgHTMLAttributes } from 'react';
import merge from 'classnames';

import styles from './ApplicationLogo.module.scss';

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  // Disallowed props
  src?: never;
  alt?: never;
  onClick?: () => void;
  className?: string;
};

export const ApplicationLogo = (props: Props) => {
  const { onClick, className, ...imgProps } = props;

  return (
    <img
      data-testid={ApplicationLogo._jestTestId}
      {...imgProps}
      className={merge(styles.logo, { [styles.clickable]: onClick }, className)}
      onClick={onClick}
      src="/assets/images/Logo.png"
      alt="GAF Energy Logo"
    />
  );
};

ApplicationLogo._jestTestId = 'application-logo';
