import { CaptureConsole, ExtraErrorData, HttpClient } from '@sentry/integrations';
import * as SentryClient from '@sentry/react';

import { EnvironmentService } from 'services/Environment';
import { HomeOwnerVm } from 'types';

class SentryService {
  private get isEnabled() {
    return EnvironmentService.isCERT() || EnvironmentService.isPROD();
  }

  public init() {
    if (!this.isEnabled) {
      return;
    }

    SentryClient.init({
      dsn: process.env.REACT_APP_SENTRY_DSN_URL,
      allowUrls: [/^\w+\.gaf\.energy$/], // Add /https:\/\/localhost:44334/ if you need to test integrations locally
      ignoreErrors: ['top.GLOBALS'],
      integrations: [
        new SentryClient.BrowserTracing(),
        new SentryClient.Replay(),
        new ExtraErrorData(),
        new CaptureConsole({
          levels: ['error'],
        }),
        new HttpClient({
          failedRequestStatusCodes: [400, [402, 599]],
        }),
      ],
      replaysSessionSampleRate: EnvironmentService.isDEV() ? 0 : 0.05,
      replaysOnErrorSampleRate: EnvironmentService.isDEV() ? 0 : 1.0,
      tracesSampleRate: EnvironmentService.isPROD() ? 1.0 : 0.25,
      environment: EnvironmentService.currentEnv,
    });
  }

  public setUser(homeOwnerInfo: HomeOwnerVm) {
    if (!this.isEnabled) {
      return;
    }

    SentryClient.setUser(homeOwnerInfo);
  }

  public clearUser() {
    if (!this.isEnabled) {
      return;
    }

    SentryClient.setUser(null);
  }

  public captureError(error: Error) {
    if (!this.isEnabled) {
      return;
    }

    SentryClient.captureException(error);
  }
}

export const Sentry = new SentryService();
