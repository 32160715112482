import last from 'lodash/last';
import { DateTime, DurationUnit } from 'luxon';

import { AnalyzeAction, AnalyzeMode, EnergyProduction } from 'store/reducers/property/types';
import { EnergyProductionInterval, SystemEnergyVm } from 'types';

import { Input, Output } from './types';

export const EnergyProductionService = (() => {
  const getTotalEnergyProductionValue = (
    records: Array<SystemEnergyVm | EnergyProduction>,
    toFixed?: number,
  ): number => {
    const total = records.reduce((result, record) => result + record?.value, 0);

    return toFixed === undefined ? total : +total.toFixed(toFixed);
  };

  const getHighestEnergyProductionValue = (
    records: Array<SystemEnergyVm | EnergyProduction>,
    toFixed?: number,
  ): number => {
    const highest = records.reduce((result, { value }) => Math.max(result, value), 0);

    return toFixed === undefined ? highest : +highest.toFixed(toFixed);
  };

  const convertDateTimeToISO = (dateTIme: DateTime, alreadyInUTC = false): string => {
    return (alreadyInUTC ? dateTIme : dateTIme.toUTC()).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
  };

  const convertISOToFormat = (isoString: string, timeZone: string, format: string): string => {
    return DateTime.fromISO(isoString).setZone(timeZone).toFormat(format);
  };

  const getInterval = (input: Input): EnergyProductionInterval => {
    switch (input.mode) {
      case AnalyzeMode.Day:
        return EnergyProductionInterval.Hourly;

      case AnalyzeMode.Week:
        return EnergyProductionInterval.Daily;

      case AnalyzeMode.Month:
        return EnergyProductionInterval.Daily;

      case AnalyzeMode.Year:
        return EnergyProductionInterval.Monthly;

      default:
        return EnergyProductionInterval.Yearly;
    }
  };

  const checkIfDateRangeIsProcessableBySDDC = (
    startTimestampDateTime: DateTime,
    endTimestampDateTime: DateTime,
    input: Required<Omit<Input, 'action'>>,
  ): Output => {
    let processableBySDDC = true;
    let energyProduction: EnergyProduction[] = [];

    const startTimestamp = convertDateTimeToISO(startTimestampDateTime);
    const endTimestamp = convertDateTimeToISO(endTimestampDateTime);

    if (
      startTimestampDateTime >= DateTime.now() ||
      endTimestampDateTime <= DateTime.fromISO(input.firstRecordDate)
    ) {
      processableBySDDC = false;
      energyProduction = fillMissingRecords(
        [{ date: convertDateTimeToISO(startTimestampDateTime), value: 0 }],
        { ...(input as Required<Input>), startTimestamp, endTimestamp },
      );
    }

    return {
      processableBySDDC,
      energyProduction,
      dateRange: { interval: getInterval(input), startTimestamp, endTimestamp },
    };
  };

  const fillMissingRecords = (
    records: SystemEnergyVm[],
    input: Omit<Required<Input>, 'action' | 'firstRecordDate'>,
  ): EnergyProduction[] => {
    /*
      Original data provided by the API can have missing records anywhere in the list.

      At the start because the solar system has an installation date = no records before that date.
      In the middle because the solar system could have been turned OFF for maintenance.
      At the end because that will be the future = no records after current time.

      The idea is to create our own list without any missing record (missing records will have
      a value equal to 0) and map actual values from original list to our list.
    */

    // Determine record date shift based on the graph's mode
    const shift: DurationUnit = (() => {
      switch (input.mode) {
        case AnalyzeMode.Day:
          return 'hour';

        case AnalyzeMode.Week:
        case AnalyzeMode.Month:
          return 'day';

        case AnalyzeMode.Year:
          return 'month';

        default:
          return 'year';
      }
    })();

    // Create a "date ~ value" map from the original records list
    const dateValueMap = records.reduce<{ [key: string]: number }>(
      (result, record) => ({
        ...result,
        // Covert the date to the start of the "shift" in property timeZone
        // in case the record's date contains unnecessary extra data.
        //
        // Example:
        //    timeZone = -4
        //    shift = month
        //    record.data = 2021-10-15T04:00:00Z
        // Result:
        //    2021-10-01T04:00:00Z
        //
        // This is needed to access the value correctly later because records in
        // our own list won't have that unnecessary extra data and always will
        // be in the start of the "shift" in property timeZone.
        [`${record.date}Z`]: record.value,
      }),
      {},
    );

    const startTimestamp = convertDateTimeToISO(
      DateTime.fromISO(input.startTimestamp).setZone('utc').startOf('day'),
      true,
    );
    // Create our own list and add the very first record to it
    const newRecords: EnergyProduction[] = [{ date: startTimestamp, value: 0 }];

    if (dateValueMap[startTimestamp] === undefined) {
      newRecords[0].zeroed = true;
    } else {
      newRecords[0].value = dateValueMap[startTimestamp];
    }

    // Determine what the last record's date should be based on the "shift"
    const lastRecordDate = convertDateTimeToISO(
      DateTime.fromISO(input.endTimestamp)
        .setZone('utc')
        .startOf('day')
        .minus({ [shift]: 1 }),
      true,
    );

    // Fill up the rest of the list
    while (last(newRecords)!.date < lastRecordDate) {
      // Calculate the next record's date using "shift"
      const nextRecord: EnergyProduction = {
        date: convertDateTimeToISO(
          DateTime.fromISO(last(newRecords)!.date)
            .toUTC()
            .plus({ [shift]: 1 }),
          true,
        ),
        value: 0,
      };

      // Check if the original list has a value for the date and save
      // it if its present or mark the record as "zeroed" otherwise
      if (dateValueMap[nextRecord.date] === undefined) {
        nextRecord.zeroed = true;
      } else {
        nextRecord.value = dateValueMap[nextRecord.date];
      }

      newRecords.push(nextRecord);
    }

    return newRecords;
  };

  const getDateRange = (input: Input): Output => {
    if (!input.startTimestamp || !input.endTimestamp || !input.firstRecordDate) {
      return getCurrentDateRange(input);
    }

    switch (input.action) {
      case AnalyzeAction.Previous:
        return getPreviousDateRange(input as Required<Input>);

      default:
      case AnalyzeAction.Current:
        return getCurrentDateRange(input);

      case AnalyzeAction.Refresh:
        return getTargetDateRange(input as Required<Input>);

      case AnalyzeAction.Next:
        return getNextDateRange(input as Required<Input>);
    }
  };

  const getPreviousDateRange = (input: Required<Omit<Input, 'action'>>): Output => {
    let startTimestamp = DateTime.fromISO(input.startTimestamp).setZone(input.timeZone);
    let endTimestamp = DateTime.fromISO(input.endTimestamp).setZone(input.timeZone);

    switch (input.mode) {
      case AnalyzeMode.Day: {
        startTimestamp = startTimestamp.minus({ days: 1 });
        endTimestamp = endTimestamp.minus({ days: 1 });
        break;
      }

      case AnalyzeMode.Week: {
        startTimestamp = startTimestamp.minus({ weeks: 1 });
        endTimestamp = endTimestamp.minus({ weeks: 1 });
        break;
      }

      case AnalyzeMode.Month: {
        // 2021-10-01 00:00:00
        startTimestamp = startTimestamp.minus({ months: 1 });

        // 2021-10-31 23:59:59
        // endTimestamp = startTimestamp.endOf('month');

        // 2021-11-01 00:00:00
        endTimestamp = endTimestamp.minus({ months: 1 });
        break;
      }

      case AnalyzeMode.Year: {
        startTimestamp = startTimestamp.minus({ years: 1 });
        endTimestamp = endTimestamp.minus({ years: 1 });
        break;
      }
    }

    return checkIfDateRangeIsProcessableBySDDC(startTimestamp, endTimestamp, input);
  };

  const getCurrentDateRange = (input: Input): Output => {
    // Get current time in property time zone
    const currentTime = DateTime.fromObject({}, { zone: input.timeZone });
    let startTimestamp = currentTime;
    let endTimestamp = currentTime;

    switch (input.mode) {
      case AnalyzeMode.Day: {
        // 2021-10-05 00:00:00
        startTimestamp = startTimestamp.startOf('day');

        // 2021-10-05 23:59:59
        // endTimestamp = endTimestamp.endOf('day');

        // 2021-10-06 00:00:00
        endTimestamp = startTimestamp.plus({ days: 1 });
        break;
      }

      case AnalyzeMode.Week: {
        // In Luxon week starts at Monday, therefore we subtract
        // 1 day to account for US week that starts at Sunday

        // 2021-10-03 00:00:00
        startTimestamp = startTimestamp.startOf('week').minus({ days: 1 });

        // 2021-10-09 23:59:59
        // endTimestamp = endTimestamp.endOf('week').minus({ days: 1 });

        // 2021-10-10 00:00:00
        endTimestamp = startTimestamp.plus({ weeks: 1 });
        break;
      }

      case AnalyzeMode.Month: {
        // 2021-10-01 00:00:00
        startTimestamp = startTimestamp.startOf('month');

        // 2021-10-31 23:59:59
        // endTimestamp = endTimestamp.endOf('month');

        // 2021-11-01 00:00:00
        endTimestamp = startTimestamp.plus({ months: 1 });
        break;
      }

      case AnalyzeMode.Year: {
        // 2021-01-01 00:00:00
        startTimestamp = startTimestamp.startOf('year');

        // 2021-12-31 23:59:59
        // endTimestamp = endTimestamp.endOf('year');

        // 2022-01-01 00:00:00
        endTimestamp = startTimestamp.plus({ years: 1 });
        break;
      }
    }

    return {
      processableBySDDC: true,
      energyProduction: [],
      dateRange: {
        interval: getInterval(input),
        startTimestamp: convertDateTimeToISO(startTimestamp),
        endTimestamp: convertDateTimeToISO(endTimestamp),
      },
    };
  };

  const getTargetDateRange = (input: Required<Omit<Input, 'action'>>): Output => {
    const startTimestamp = DateTime.fromISO(input.startTimestamp).setZone(input.timeZone);
    const endTimestamp = DateTime.fromISO(input.endTimestamp).setZone(input.timeZone);

    return checkIfDateRangeIsProcessableBySDDC(startTimestamp, endTimestamp, input);
  };

  const getNextDateRange = (input: Required<Omit<Input, 'action'>>): Output => {
    let startTimestamp = DateTime.fromISO(input.startTimestamp).setZone(input.timeZone);
    let endTimestamp = DateTime.fromISO(input.endTimestamp).setZone(input.timeZone);

    switch (input.mode) {
      case AnalyzeMode.Day: {
        startTimestamp = startTimestamp.plus({ days: 1 });
        endTimestamp = endTimestamp.plus({ days: 1 });
        break;
      }

      case AnalyzeMode.Week: {
        startTimestamp = startTimestamp.plus({ weeks: 1 });
        endTimestamp = endTimestamp.plus({ weeks: 1 });
        break;
      }

      case AnalyzeMode.Month: {
        // 2021-10-01 00:00:00
        startTimestamp = startTimestamp.plus({ months: 1 });

        // 2021-10-31 23:59:59
        // endTimestamp = startTimestamp.endOf('month');

        // 2021-11-01 00:00:00
        endTimestamp = endTimestamp.plus({ months: 1 });
        break;
      }

      case AnalyzeMode.Year: {
        startTimestamp = startTimestamp.plus({ years: 1 });
        endTimestamp = endTimestamp.plus({ years: 1 });
        break;
      }
    }

    return checkIfDateRangeIsProcessableBySDDC(startTimestamp, endTimestamp, input);
  };

  const getLast7DaysDateRange = (timeZone: Input['timeZone']): Output => {
    // Get current time in property time zone
    const currentTime = DateTime.fromObject({}, { zone: timeZone });

    return {
      processableBySDDC: true,
      energyProduction: [],
      dateRange: {
        interval: EnergyProductionInterval.Daily,
        startTimestamp: convertDateTimeToISO(currentTime.startOf('day').minus({ days: 6 })),
        endTimestamp: convertDateTimeToISO(currentTime.endOf('day').plus({ milliseconds: 1 })),
      },
    };
  };

  return {
    getEnergyValue: {
      total: getTotalEnergyProductionValue,
      highest: getHighestEnergyProductionValue,
    },

    convert: {
      dateTimeToISO: convertDateTimeToISO,
      isoToFormat: convertISOToFormat,
    },

    getDateRange,
    getLast7DaysDateRange,
    fillMissingRecords,
  };
})();
