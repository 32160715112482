import React from 'react';
import { createRoot } from 'react-dom/client';

import { ApplicationRootContainer } from 'containers/ApplicationRoot';
import { Ampli } from 'services/Ampli';
import { Sentry } from 'services/Sentry';
import { ROOT_ELEMENT_ID } from 'variables';

import '@gafe/common-ui/styles.css';
import 'styles/index.scss';

Sentry.init();
Ampli.init();

grecaptcha.ready(() => {
  const container = document.getElementById(ROOT_ELEMENT_ID) as HTMLElement;
  const root = createRoot(container);

  root.render(<ApplicationRootContainer />);
});
