enum ENV {
  TEST = 'TEST',
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  QA = 'QA',
  STG = 'STG',
  CERT = 'CERT',
  PROD = 'PROD',
}

export const EnvironmentService = new (class {
  public get currentEnv(): ENV {
    return process.env.REACT_APP_BUILD_ENV as ENV;
  }

  public isDEV = (): boolean => this.currentEnv === ENV.DEV || this.currentEnv === ENV.LOCAL;
  public isQA = (): boolean => this.currentEnv === ENV.QA;
  public isSTG = (): boolean => this.currentEnv === ENV.STG;
  public isCERT = (): boolean => this.currentEnv === ENV.CERT;
  public isPROD = (): boolean => this.currentEnv === ENV.PROD;

  get withLogs() {
    return [ENV.LOCAL, ENV.DEV, ENV.QA].includes(this.currentEnv);
  }

  public getApplicationRootURL = (): string => {
    switch (this.currentEnv) {
      case ENV.DEV:
        return 'https://my.gafdev.energy/';

      case ENV.STG:
        return 'https://my.gafstg.energy/';

      case ENV.CERT:
        return 'https://my.gafcert.energy/';

      case ENV.PROD:
        return 'https://my.gaf.energy/';

      case ENV.TEST:
      case ENV.LOCAL:
      default:
        return 'https://localhost:44334/';
    }
  };

  public getBackendAPIRootURL = (): string => {
    switch (this.currentEnv) {
      case ENV.STG:
        return 'https://wamy.gafstg.energy/';

      case ENV.CERT:
        return 'https://wamy.gafcert.energy/';

      case ENV.PROD:
        return 'https://wamy.gaf.energy/';

      case ENV.TEST:
      case ENV.LOCAL:
      case ENV.DEV:
      default:
        return 'https://wamy.gafdev.energy/';
    }
  };

  public getGoogleReCaptchaHeaderKey = (): string => {
    return 'x-recaptcha-response';
  };

  public getGoogleReCaptchaAPIKey = (): string => {
    return process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string;
  };
})();
