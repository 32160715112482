export type GlobalState = {
  unexpectedError: unknown;
  loggingUserOut: boolean;
};

export enum GlobalActionTypes {
  SET_UNEXPECTED_ERROR = 'global|setUnexpectedError',
}

export interface SetUnexpectedErrorAction {
  type: GlobalActionTypes.SET_UNEXPECTED_ERROR;
  error: unknown;
}

export type GlobalActions = SetUnexpectedErrorAction;
