import React from 'react';

import { useWindowSize } from 'hooks/useWindowSize';
import { BrowserStorageService } from 'services/BrowserStorage';

import { Props } from './types';

import styles from './AssistingBanner.module.scss';

export const AssistingBanner = (props: Props) => {
  const { accountInfo, hideDesktop } = props;
  const window = useWindowSize();

  if (hideDesktop && window.width > 1024) {
    return null;
  }

  if (
    !accountInfo ||
    !accountInfo?.homeOwnerInfo ||
    !accountInfo?.homeOwnerInfo.assistingAdminId ||
    !BrowserStorageService.csrfToken.get(true)
  ) {
    return null;
  }

  const { assistingAdminName, firstName, lastName } = accountInfo.homeOwnerInfo;

  return (
    <div
      id={AssistingBanner.containerId}
      data-testid="assisting-banner"
      className={styles.container}
    >
      {`${assistingAdminName} assisting ${firstName} ${lastName}`}
    </div>
  );
};

AssistingBanner.containerId = 'assisting-banner-container';
