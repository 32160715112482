import React, { HTMLAttributes } from 'react';
import merge from 'classnames';

import styles from './Page.module.scss';

export type Props = HTMLAttributes<HTMLDivElement> & {
  title: string;
  subtitle?: string;
  // Disallowed props
  children?: never;
  className?: string;
};

export const PageTitle = (props: Props) => {
  const { title, subtitle, className, ...divProps } = props;

  return (
    <div
      data-testid={PageTitle._jestTestId}
      {...divProps}
      className={merge(styles.section, styles.title, className)}
    >
      <h1>{title}</h1>
      {subtitle && <p>{subtitle}</p>}
    </div>
  );
};

PageTitle._jestTestId = 'page-title';
