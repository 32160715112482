import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';

import { BrowserStorageService } from 'services/BrowserStorage/types';
import { LOGOUT_URL } from 'variables';

export type CancellableRequestsStorage = { [requestName: string]: CancelTokenSource };
export const REQUESTS_STORAGE: CancellableRequestsStorage = {};
export const CANCELLATION_MESSAGE = 'AxiosService: Cancelling previous request!';

export const requestCancellationRequestInterceptor =
  (storage: CancellableRequestsStorage) =>
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (config.url) {
      // Cancel all ongoing requests when logout is triggered
      if (config.url === LOGOUT_URL) {
        Object.keys(storage).forEach(url => {
          if (url !== LOGOUT_URL) {
            storage[url].cancel(CANCELLATION_MESSAGE);
          }
        });
      }

      // Only "GET" requests should be cancellable
      if (config.url && (config.method === 'get' || config.method === 'GET')) {
        // If there is a request to the same API already running - cancel it and
        // leave only the latest one which is also happened to be the current one
        if (storage[config.url]) storage[config.url].cancel(CANCELLATION_MESSAGE);
      }

      // Add request API url to the "requests storage" so we
      // will know if we need to cancel it in the future
      storage[config.url] = axios.CancelToken.source();
      // Add cancel token to the request config, so
      // we can cancel it in the future if needed
      config.cancelToken = storage[config.url].token;
    }

    return config;
  };

// TODO: implement requestCancellationResponseInterceptor (clear from the storage)
// https://github.com/axios/axios#interceptors
export const requestCancellationResponseInterceptor =
  (storage: CancellableRequestsStorage) => (response: AxiosResponse) => {
    if (response && response.config && response.config.url) {
      delete storage[response.config.url];
    }

    return response;
  };

export const csrfTokenRequestInterceptor =
  (storage: BrowserStorageService) =>
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    // Get portal csrf token from the browser storage
    const portalCsrfToken = storage.csrfToken.get() || storage.csrfToken.get(true);

    // If we have a csrf token and the request doesn't have
    // any authorization headers already - add a csrf token
    // as an authorization header to the current request

    if (config.headers) {
      if (portalCsrfToken && !config.headers['x-csrf-token']) {
        config.headers['x-csrf-token'] = portalCsrfToken;
      }
    }

    return config;
  };
