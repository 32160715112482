import { takeEvery } from '@redux-saga/core/effects';
import { all } from 'redux-saga/effects';

import { PropertyControllerSagas } from 'controllers/PropertyController';
import { UserControllerSagas } from 'controllers/UserController';
import { UserActionTypes } from 'store/reducers/user/types';

import { ApplicationStartUpSaga } from './applicationStartUp';
import { GenericApiRequestSaga, GenericApiRequestSagaActionPattern } from './genericApiRequest';
import { InactivityMonitoringSaga } from './inactivityMonitoring';

function* GlobalSagas() {
  yield takeEvery(UserActionTypes.VALIDATE_CSRF_TOKEN__START, ApplicationStartUpSaga);
  yield takeEvery(UserActionTypes.INACTIVITY_MONITORING__START, InactivityMonitoringSaga);
  yield takeEvery(GenericApiRequestSagaActionPattern, GenericApiRequestSaga);
}

export function* rootSaga() {
  yield all([GlobalSagas(), PropertyControllerSagas(), UserControllerSagas()]);
}
