import React from 'react';
import merge from 'classnames';

import { addDefault } from './helpers';
import { SvgIconContainer } from './SvgIconContainer';
import { Props } from './types';

export const IconLeaderboard = (props: Props) => {
  const { onClick, classes, color } = addDefault(props);

  return (
    <SvgIconContainer className={classes.container} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        viewBox="0 0 24 24"
        height="24px"
        width="24px"
        fill={color}
        className={merge(classes.icon)}
      >
        <rect fill="none" height="24" width="24" />
        <path d="M7.5,21H2V9h5.5V21z M14.75,3h-5.5v18h5.5V3z M22,11h-5.5v10H22V11z" />
      </svg>
    </SvgIconContainer>
  );
};
