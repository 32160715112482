import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';

import { AnyFunction } from 'types';

type ReturnTypeOrObject<MapToProps> = MapToProps extends null | undefined
  ? any
  : MapToProps extends AnyFunction
  ? ReturnType<MapToProps>
  : MapToProps;

export type ConnectProps<MapState = null, MapDispatch = null> = ReturnTypeOrObject<MapState> &
  ReturnTypeOrObject<MapDispatch>;

export interface GenericApiActionStart {
  type: string;
  apiRequest: (...args: any[]) => AxiosPromise;
  payload?: any[];
  followupActions: {
    SUCCESS: (response: AxiosResponse) => GenericApiActionSuccess;
    FAIL: (error: AxiosError) => GenericApiActionFail;
  };
}

export interface GenericApiActionSuccess {
  type: string;
  payload: AxiosResponse;
}

export interface GenericApiActionFail {
  type: string;
  error: AxiosError;
}

export enum SagaHelperExitCode {
  STOP,
  CONTINUE,
}
