import { AxiosPromise } from 'axios';

import { Axios, AxiosService } from 'services/Axios';
import { BrowserStorageService } from 'services/BrowserStorage';
import { EnvironmentService } from 'services/Environment';
import {
  ChangePasswordRequestClientVm,
  ChangePasswordRequestVm,
  CheckEmailAddressRequestVm,
  FinishRegistrationOfExistingHomeOwnerRequestClientVm,
  FinishRegistrationOfExistingHomeOwnerRequestVm,
  LogInHomeOwnerRequestClientVm,
  LogInHomeOwnerRequestVm,
  RegisterNewHomeOwnerRequestClientVm,
  RegisterNewHomeOwnerRequestVm,
  ResendVerificationEmailRequestVm,
  SendPasswordResetLinkRequestVm,
  VerifyEmailAddressRequestVm,
  VerifyResetPasswordLinkRequestVm,
  WrappedCheckEmailAddressResponseVm,
  WrappedFinishRegistrationOfExistingHomeOwnerResponseVm,
  WrappedHomeOwnerAccountInfoVm,
  WrappedPasswordResetResponseVm,
  WrappedResponseVm,
  WrappedVerifyEmailResponseVm,
} from 'types';
import { stringToArrayOfStrings } from 'utils/string-to-array-of-strings';
import { LOGOUT_URL } from 'variables';

export class UserControllerClass {
  constructor(private APIService: AxiosService) {}

  private async reCaptchaHeader(action: string) {
    const xReCaptchaId = BrowserStorageService.xReCaptchaId.get();

    return {
      headers: {
        'x-recaptcha-response':
          xReCaptchaId ??
          (await grecaptcha.execute(EnvironmentService.getGoogleReCaptchaAPIKey(), {
            action,
          })),
      },
    };
  }
  /**
   * Validate user's csrf token.
   * This endpoint returns an empty response with successful status code if the token is valid, otherwise it returns a 401 response.
   * Protected by JWT authorization.
   */
  validateCsrfToken = (): AxiosPromise<WrappedResponseVm> => {
    return this.APIService.post('auth/validate-token');
  };

  /**
   * Check if email address can be used to register a new homeowner.
   * Protected by ReCaptcha v3.
   */
  checkNewUserEmailAddress = async (
    payload: CheckEmailAddressRequestVm,
  ): Promise<AxiosPromise<WrappedCheckEmailAddressResponseVm>> => {
    return this.APIService.post(
      'homeowner/check-email',
      payload,
      await this.reCaptchaHeader('check_email'),
    );
  };

  /**
   * Register a new homeowner.
   * Protected by ReCaptcha v3.
   */
  registerNewHomeowner = async (
    clientPayload: RegisterNewHomeOwnerRequestClientVm,
    action: string,
  ): Promise<AxiosPromise<WrappedResponseVm>> => {
    // Convert client payload into server payload
    const serverPayload: RegisterNewHomeOwnerRequestVm = {
      ...clientPayload,
      password: stringToArrayOfStrings(clientPayload.password),
    };

    return this.APIService.post(
      'homeowner/new/register',
      serverPayload,
      await this.reCaptchaHeader(action),
    );
  };

  /**
   * Finish registration of existing homeowner.
   * Protected by ReCaptcha v3.
   */
  registerExistingHomeowner = async (
    clientPayload: FinishRegistrationOfExistingHomeOwnerRequestClientVm,
    action: string,
  ): Promise<AxiosPromise<WrappedFinishRegistrationOfExistingHomeOwnerResponseVm>> => {
    // Convert client payload into server payload
    const serverPayload: FinishRegistrationOfExistingHomeOwnerRequestVm = {
      ...clientPayload,
      password: stringToArrayOfStrings(clientPayload.password),
    };

    return this.APIService.post(
      'homeowner/existing/finish-registration',
      serverPayload,
      await this.reCaptchaHeader(action),
    );
  };

  /**
   * Verifies homeowner's email address. Protected by ReCaptcha v3.
   */
  verifyEmailAddress = async (
    payload: VerifyEmailAddressRequestVm,
  ): Promise<AxiosPromise<WrappedVerifyEmailResponseVm>> => {
    return this.APIService.post(
      'homeowner/verify-email',
      payload,
      await this.reCaptchaHeader('verify_email'),
    );
  };

  /**
   * Resending verification link to the specified homeowner's email address. Protected by ReCaptcha v3
   */
  resendVerificationEmail = async (
    payload: ResendVerificationEmailRequestVm,
  ): Promise<AxiosPromise<WrappedResponseVm>> => {
    return this.APIService.post(
      'homeowner/resend-verification-email',
      payload,
      await this.reCaptchaHeader('resend_verification_email'),
    );
  };

  /**
   * Log registered homeowner in.
   * Protected by ReCaptcha v3.
   */
  logIn = async (
    clientPayload: LogInHomeOwnerRequestClientVm,
  ): Promise<AxiosPromise<WrappedCheckEmailAddressResponseVm>> => {
    // Convert client payload into server payload
    const serverPayload: LogInHomeOwnerRequestVm = {
      ...clientPayload,
      password: stringToArrayOfStrings(clientPayload.password),
    };

    return this.APIService.post('auth/login', serverPayload, await this.reCaptchaHeader('login'));
  };

  /**
   * Log in Admin as Homeowner user.
   * Protected by ReCaptcha v3.
   */
  logInAdminAsHomeowner = async ({
    adminLoginSessionId,
  }: any): Promise<AxiosPromise<WrappedCheckEmailAddressResponseVm>> => {
    // Convert client payload into server payload

    return this.APIService.post(
      'auth/login-admin-as-homeowner',
      { adminLoginSessionId },
      await this.reCaptchaHeader('login_admin_as_homeowner'),
    );
  };

  /**
   * Log authorized homeowner out.
   * Protected by JWT authorization.
   */
  logOut = (): AxiosPromise<WrappedResponseVm> => {
    return this.APIService.post(LOGOUT_URL);
  };

  /**
   * Get information about account of current homeowner (user).
   * Protected by JWT authorization.
   */
  fetchAccountInfo = (): AxiosPromise<WrappedHomeOwnerAccountInfoVm> => {
    return this.APIService.get('/auth/get-account-info');
  };

  /**
   * Send password reset link to the homeowner (user).
   * Protected by ReCaptcha v3.
   */
  sendPasswordResetLink = async (
    payload: SendPasswordResetLinkRequestVm,
  ): Promise<AxiosPromise<WrappedPasswordResetResponseVm>> => {
    return this.APIService.post(
      '/homeowner/send-password-reset-link',
      payload,
      await this.reCaptchaHeader('send_password_reset_link'),
    );
  };

  /**
   * Verifies homeowner's reset password link.
   * Protected by ReCaptcha v3.
   */
  verifyResetPasswordLink = async (
    payload: VerifyResetPasswordLinkRequestVm,
  ): Promise<AxiosPromise<WrappedPasswordResetResponseVm>> => {
    return this.APIService.post(
      'homeowner/verify-reset-password-link',
      payload,
      await this.reCaptchaHeader('verify_reset_password_link'),
    );
  };

  /**
   * Changes homeowner's password.
   * Protected by ReCaptcha v3.
   */
  changePassword = async (
    clientPayload: ChangePasswordRequestClientVm,
  ): Promise<AxiosPromise<WrappedPasswordResetResponseVm>> => {
    const payload: ChangePasswordRequestVm = {
      ...clientPayload,
      newPassword: stringToArrayOfStrings(clientPayload.newPassword),
    };

    return this.APIService.post(
      'homeowner/change-password',
      payload,
      await this.reCaptchaHeader('change_password'),
    );
  };
}

export const UserController = new UserControllerClass(Axios);
