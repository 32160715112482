import throttle from 'lodash/throttle';

import { BrowserStorageService } from 'services/BrowserStorage';
import { EnvironmentService } from 'services/Environment';

type Config = {
  debug: boolean;
  activityActions: (keyof DocumentEventMap)[];
  maxIdleTime: number;
  checkInactivityEvery: number;
  onIdleTimeEndCallback: () => void;
};

interface Interface {
  validateInactivityStatus(inactivityOptions: Partial<Config>): void;
  updateLastActivityTime(): void;
  start(startOptions?: Partial<Config>): void;
  stop(): void;
}

export const InactivityMonitoringService: Interface = new (class {
  private interval?: NodeJS.Timeout;
  private config: Config = {
    debug: false,
    activityActions: ['click', 'scroll'],
    maxIdleTime: 1000 * 60 * 60, // 1 hour
    checkInactivityEvery: 1000 * 60, // 1 minute
    onIdleTimeEndCallback: () => void 0,
  };

  private debugLog = (...args: (string | string[])[]) => {
    if (EnvironmentService.withLogs) {
      console.groupCollapsed('Inactivity Monitoring Service');
      args.forEach(data => console.log(data));
      console.groupEnd();
    }
  };

  private checkInactivity = (inactivityOptions = {}) => {
    const updatedConfig = { ...this.config, ...inactivityOptions };
    const { maxIdleTime, checkInactivityEvery, onIdleTimeEndCallback } = updatedConfig;
    const timestamp = BrowserStorageService.lastActivity.get();

    if (!timestamp) {
      return this.debugLog('Check user inactivity', 'Last activity time is missing');
    }

    this.debugLog(
      'Check user inactivity',
      `Last activity at ${new Date(timestamp)}`,
      `Milliseconds passed since last activity = ${+Date.now() - timestamp}`,
      `Max amount of milliseconds since last activity = ${maxIdleTime}`,
      `User will be auto logged out at ${new Date(timestamp + maxIdleTime)}`,
    );

    if (+Date.now() - timestamp >= maxIdleTime) {
      this.debugLog('Logging use out');
      BrowserStorageService.lastActivity.remove();

      return onIdleTimeEndCallback();
    }

    return this.debugLog(`Next check in ${checkInactivityEvery} milliseconds`);
  };

  updateLastActivityTime: Interface['updateLastActivityTime'] = throttle(() => {
    if (this.interval) {
      this.debugLog(
        'Store last activity timestamp',
        'Next storing action is possible in 30 seconds',
        `User will be auto logged out at ${new Date(+Date.now() + this.config.maxIdleTime)}`,
      );
      BrowserStorageService.lastActivity.set();
    }
  }, 1000 * 30);

  validateInactivityStatus: Interface['validateInactivityStatus'] = throttle(
    (inactivityOptions = {}) => this.checkInactivity(inactivityOptions),
    800,
  );

  start: Interface['start'] = (options = {}) => {
    // Check inactivity prior to continue and reset handlers
    this.validateInactivityStatus({ ...this.config, ...options });
    this.stop();

    if (this.interval) return;

    this.debugLog(['Service start', 'Apply user config']);
    this.config = { ...this.config, ...options };

    BrowserStorageService.lastActivity.set();

    this.config.activityActions.forEach(trigger => {
      document.addEventListener(trigger, this.updateLastActivityTime, true);
    });

    this.interval = setInterval(this.checkInactivity, this.config.checkInactivityEvery);
  };

  stop: Interface['stop'] = () => {
    if (!this.interval) return;
    this.debugLog('Service stop');
    clearInterval(this.interval);
    this.interval = undefined;
    this.config.activityActions.forEach(trigger => {
      document.removeEventListener(trigger, this.updateLastActivityTime, true);
    });
  };
})();
