import { AxiosError, AxiosResponse } from 'axios';

import { UserController } from 'controllers/UserController';
import {
  ChangePasswordRequestClientVm,
  CheckEmailAddressRequestVm,
  EmailAddressStatus,
  FinishRegistrationOfExistingHomeOwnerRequestClientVm,
  FinishRegistrationOfExistingHomeOwnerResponseVm,
  GenericApiActionFail,
  GenericApiActionSuccess,
  HomeOwnerAccountInfoVm,
  LogInHomeOwnerRequestClientVm,
  LogInStatus,
  PasswordResetStatus,
  RegisterNewHomeOwnerRequestClientVm,
  ResendVerificationEmailRequestVm,
  SendPasswordResetLinkRequestVm,
  VerifyEmailAddressRequestVm,
  VerifyEmailAddressResponseVm,
  VerifyResetPasswordLinkRequestVm,
  WrappedCheckEmailAddressResponseVm,
  WrappedFinishRegistrationOfExistingHomeOwnerResponseVm,
  WrappedHomeOwnerAccountInfoVm,
  WrappedLogInHomeOwnerResponseVm,
  WrappedPasswordResetResponseVm,
  WrappedResponseVm,
  WrappedVerifyEmailResponseVm,
} from 'types';

export enum UserActionTypes {
  MARK_USER_AS_LOGGED_IN = 'user|markAsLoggedIn',
  VALIDATE_CSRF_TOKEN__START = 'user|validateCsrfToken:start',
  VALIDATE_CSRF_TOKEN__SUCCESS = 'user|validateCsrfToken:success',
  VALIDATE_CSRF_TOKEN__FAIL = 'user|validateCsrfToken:fail',
  INACTIVITY_MONITORING__START = 'user[inactivityMonitoring:start]',
  INACTIVITY_MONITORING__SUCCESS = 'user[inactivityMonitoring:success]',
  INACTIVITY_MONITORING__FAIL = 'user[inactivityMonitoring:fail]',
  CHECK_NEW_USER_EMAIL_ADDRESS__START = 'user|checkNewUserEmailAddress:start',
  CHECK_NEW_USER_EMAIL_ADDRESS__SUCCESS = 'user|checkNewUserEmailAddress:success',
  CHECK_NEW_USER_EMAIL_ADDRESS__FAIL = 'user|checkNewUserEmailAddress:fail',
  LOG_IN__START = 'user|logIn:start',
  LOG_IN__SUCCESS = 'user|logIn:success',
  LOG_IN__FAIL = 'user|logIn:fail',
  LOG_IN_ADMIN_AS_HOMEOWNER__START = 'user|logInAdminAsHomeowner:start',
  LOG_IN_ADMIN_AS_HOMEOWNER__SUCCESS = 'user|logInAdminAsHomeowner:success',
  LOG_IN_ADMIN_AS_HOMEOWNER__FAIL = 'user|logInAdminAsHomeowner:fail',
  LOG_OUT__START = 'user|logOut:start',
  LOG_OUT__SUCCESS = 'user|logOut:success',
  REGISTER_NEW_HOMEOWNER__START = 'user|registerNewHomeowner:start',
  REGISTER_NEW_HOMEOWNER__SUCCESS = 'user|registerNewHomeowner:success',
  REGISTER_NEW_HOMEOWNER__FAIL = 'user|registerNewHomeowner:fail',
  REGISTER_EXISTING_HOMEOWNER__START = 'user|registerExistingHomeowner:start',
  REGISTER_EXISTING_HOMEOWNER__SUCCESS = 'user|registerExistingHomeowner:success',
  REGISTER_EXISTING_HOMEOWNER__FAIL = 'user|registerExistingHomeowner:fail',
  VERIFY_EMAIL_ADDRESS__START = 'user|verifyEmailAddress:start',
  VERIFY_EMAIL_ADDRESS__SUCCESS = 'user|verifyEmailAddress:success',
  VERIFY_EMAIL_ADDRESS__FAIL = 'user|verifyEmailAddress:fail',
  RESEND_VERIFICATION_EMAIL__START = 'user|resendVerificationEmail:start',
  RESEND_VERIFICATION_EMAIL__SUCCESS = 'user|resendVerificationEmail:success',
  RESEND_VERIFICATION_EMAIL__FAIL = 'user|resendVerificationEmail:fail',
  FETCH_ACCOUNT_INFO__START = 'user|fetchAccountInfo:start',
  FETCH_ACCOUNT_INFO__SUCCESS = 'user|fetchAccountInfo:success',
  FETCH_ACCOUNT_INFO__FAIL = 'user|fetchAccountInfo:fail',
  REQUEST_PASSWORD_RESET_EMAIL__START = 'user|requestPasswordResetEmail:start',
  REQUEST_PASSWORD_RESET_EMAIL__SUCCESS = 'user|requestPasswordResetEmail:success',
  REQUEST_PASSWORD_RESET_EMAIL__FAIL = 'user|requestPasswordResetEmail:fail',
  VERIFY_PASSWORD_RESET_LINK__START = 'user|verifyPasswordResetLink:start',
  VERIFY_PASSWORD_RESET_LINK__SUCCESS = 'user|verifyPasswordResetLink:success',
  VERIFY_PASSWORD_RESET_LINK__FAIL = 'user|verifyPasswordResetLink:fail',
  CHANGE_PASSWORD__START = 'user|changePassword:start',
  CHANGE_PASSWORD__SUCCESS = 'user|changePassword:success',
  CHANGE_PASSWORD__FAIL = 'user|changePassword:fail',
}

export type UserState = {
  loggedIn: boolean;
  accountInfo: HomeOwnerAccountInfoVm | null;
  passwordChange: {
    [UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__SUCCESS]?: PasswordResetStatus;
    [UserActionTypes.VERIFY_PASSWORD_RESET_LINK__SUCCESS]?: PasswordResetStatus;
    [UserActionTypes.CHANGE_PASSWORD__SUCCESS]?: PasswordResetStatus;
  };
  [UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__SUCCESS]?: EmailAddressStatus;
  [UserActionTypes.LOG_IN__SUCCESS]?: LogInStatus;
  [UserActionTypes.VERIFY_EMAIL_ADDRESS__SUCCESS]?: VerifyEmailAddressResponseVm;
  [UserActionTypes.REGISTER_EXISTING_HOMEOWNER__SUCCESS]?: FinishRegistrationOfExistingHomeOwnerResponseVm;
  [UserActionTypes.RESEND_VERIFICATION_EMAIL__SUCCESS]?: WrappedResponseVm;
};

export interface MarkUserAsLoggedInAction {
  type: UserActionTypes.MARK_USER_AS_LOGGED_IN;
}

export interface ValidateCsrfTokenActionStart {
  type: UserActionTypes.VALIDATE_CSRF_TOKEN__START;
}

export interface ValidateCsrfTokenActionSuccess {
  type: UserActionTypes.VALIDATE_CSRF_TOKEN__SUCCESS;
}

export interface ValidateCsrfTokenActionFail {
  type: UserActionTypes.VALIDATE_CSRF_TOKEN__FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface InactivityMonitoringActionStart {
  type: UserActionTypes.INACTIVITY_MONITORING__START;
}

export interface InactivityMonitoringActionSuccess {
  type: UserActionTypes.INACTIVITY_MONITORING__SUCCESS;
}

export interface InactivityMonitoringActionFail {
  type: UserActionTypes.INACTIVITY_MONITORING__FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface CheckNewUserEmailActionStart {
  type: UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__START;
  payload: [CheckEmailAddressRequestVm];
}

export interface CheckNewUserEmailActionSuccess {
  type: UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__SUCCESS;
  payload: WrappedCheckEmailAddressResponseVm;
}

export interface CheckNewUserEmailActionFail {
  type: UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__FAIL;
  error: AxiosError<WrappedCheckEmailAddressResponseVm>;
}

export interface LogInUserActionStart {
  type: UserActionTypes.LOG_IN__START;
  payload: [LogInHomeOwnerRequestClientVm];
}

export interface LogInUserActionSuccess {
  type: UserActionTypes.LOG_IN__SUCCESS;
  payload: WrappedLogInHomeOwnerResponseVm;
}

export interface LogInUserActionFail {
  type: UserActionTypes.LOG_IN__FAIL;
  error: AxiosError<WrappedLogInHomeOwnerResponseVm>;
}

export interface LogInUserAsAdminActionStart {
  type: UserActionTypes.LOG_IN_ADMIN_AS_HOMEOWNER__START;
  payload: {
    adminLoginSessionId: string;
  };
}

export interface LogInUserAsAdminActionSuccess {
  type: UserActionTypes.LOG_IN_ADMIN_AS_HOMEOWNER__SUCCESS;
  payload: WrappedLogInHomeOwnerResponseVm;
}

export interface LogInUserAsAdminActionFail {
  type: UserActionTypes.LOG_IN_ADMIN_AS_HOMEOWNER__FAIL;
  error: AxiosError<WrappedLogInHomeOwnerResponseVm>;
}

export interface LogOutUserActionStart {
  type: UserActionTypes.LOG_OUT__START;
  payload: { unauthorized: boolean };
}

export interface LogOutUserActionSuccess {
  type: UserActionTypes.LOG_OUT__SUCCESS;
}

export interface RegisterNewHomeownerUserActionStart {
  type: UserActionTypes.REGISTER_NEW_HOMEOWNER__START;
  payload: [RegisterNewHomeOwnerRequestClientVm, string];
}

export interface RegisterNewHomeownerUserActionSuccess {
  type: UserActionTypes.REGISTER_NEW_HOMEOWNER__SUCCESS;
}

export interface RegisterNewHomeownerUserActionFail {
  type: UserActionTypes.REGISTER_NEW_HOMEOWNER__FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface RegisterExistingHomeownerUserActionStart {
  type: UserActionTypes.REGISTER_EXISTING_HOMEOWNER__START;
  payload: [FinishRegistrationOfExistingHomeOwnerRequestClientVm, string];
}

export interface RegisterExistingHomeownerUserActionSuccess {
  type: UserActionTypes.REGISTER_EXISTING_HOMEOWNER__SUCCESS;
  payload: WrappedFinishRegistrationOfExistingHomeOwnerResponseVm;
}

export interface RegisterExistingHomeownerUserActionFail {
  type: UserActionTypes.REGISTER_EXISTING_HOMEOWNER__FAIL;
  error: AxiosError<WrappedFinishRegistrationOfExistingHomeOwnerResponseVm>;
}

export interface VerifyEmailAddressActionStart {
  type: UserActionTypes.VERIFY_EMAIL_ADDRESS__START;
  payload: [VerifyEmailAddressRequestVm];
}

export interface VerifyEmailAddressActionSuccess {
  type: UserActionTypes.VERIFY_EMAIL_ADDRESS__SUCCESS;
  payload: WrappedVerifyEmailResponseVm;
}

export interface VerifyEmailAddressActionFail {
  type: UserActionTypes.VERIFY_EMAIL_ADDRESS__FAIL;
  error: AxiosError<WrappedVerifyEmailResponseVm>;
}

export interface ResendVerificationEmailActionStart {
  type: UserActionTypes.RESEND_VERIFICATION_EMAIL__START;
  apiRequest: typeof UserController.resendVerificationEmail;
  payload: [ResendVerificationEmailRequestVm];
  followupActions: {
    SUCCESS: (
      payload: ResendVerificationEmailActionSuccess['payload'],
    ) => ResendVerificationEmailActionSuccess;
    FAIL: (error: ResendVerificationEmailActionFail['error']) => ResendVerificationEmailActionFail;
  };
}

export interface ResendVerificationEmailActionSuccess extends GenericApiActionSuccess {
  type: UserActionTypes.RESEND_VERIFICATION_EMAIL__SUCCESS;
  payload: AxiosResponse<WrappedResponseVm>;
}

export interface ResendVerificationEmailActionFail extends GenericApiActionFail {
  type: UserActionTypes.RESEND_VERIFICATION_EMAIL__FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface FetchAccountInfoActionStart {
  type: UserActionTypes.FETCH_ACCOUNT_INFO__START;
  apiRequest: typeof UserController.fetchAccountInfo;
  followupActions: {
    SUCCESS: (payload: FetchAccountInfoActionSuccess['payload']) => FetchAccountInfoActionSuccess;
    FAIL: (error: FetchAccountInfoActionFail['error']) => FetchAccountInfoActionFail;
  };
}

export interface FetchAccountInfoActionSuccess extends GenericApiActionSuccess {
  type: UserActionTypes.FETCH_ACCOUNT_INFO__SUCCESS;
  payload: AxiosResponse<WrappedHomeOwnerAccountInfoVm>;
}

export interface FetchAccountInfoActionFail extends GenericApiActionFail {
  type: UserActionTypes.FETCH_ACCOUNT_INFO__FAIL;
  error: AxiosError<WrappedHomeOwnerAccountInfoVm>;
}

export interface RequestPasswordResetEmailActionStart {
  type: UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__START;
  apiRequest: typeof UserController.sendPasswordResetLink;
  payload: [SendPasswordResetLinkRequestVm];
  followupActions: {
    SUCCESS: (
      payload: RequestPasswordResetEmailActionSuccess['payload'],
    ) => RequestPasswordResetEmailActionSuccess;
    FAIL: (
      error: RequestPasswordResetEmailActionFail['error'],
    ) => RequestPasswordResetEmailActionFail;
  };
}

export interface RequestPasswordResetEmailActionSuccess extends GenericApiActionSuccess {
  type: UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__SUCCESS;
  payload: AxiosResponse<WrappedPasswordResetResponseVm>;
}

export interface RequestPasswordResetEmailActionFail extends GenericApiActionFail {
  type: UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__FAIL;
  error: AxiosError<WrappedPasswordResetResponseVm>;
}

export interface VerifyPasswordResetLinkActionStart {
  type: UserActionTypes.VERIFY_PASSWORD_RESET_LINK__START;
  apiRequest: typeof UserController.verifyResetPasswordLink;
  payload: [VerifyResetPasswordLinkRequestVm];
  followupActions: {
    SUCCESS: (
      payload: VerifyPasswordResetLinkActionSuccess['payload'],
    ) => VerifyPasswordResetLinkActionSuccess;
    FAIL: (error: VerifyPasswordResetLinkActionFail['error']) => VerifyPasswordResetLinkActionFail;
  };
}

export interface VerifyPasswordResetLinkActionSuccess extends GenericApiActionSuccess {
  type: UserActionTypes.VERIFY_PASSWORD_RESET_LINK__SUCCESS;
  payload: AxiosResponse<WrappedPasswordResetResponseVm>;
}

export interface VerifyPasswordResetLinkActionFail extends GenericApiActionFail {
  type: UserActionTypes.VERIFY_PASSWORD_RESET_LINK__FAIL;
  error: AxiosError<WrappedPasswordResetResponseVm>;
}

export interface ChangePasswordActionStart {
  type: UserActionTypes.CHANGE_PASSWORD__START;
  apiRequest: typeof UserController.changePassword;
  payload: [ChangePasswordRequestClientVm];
  followupActions: {
    SUCCESS: (payload: ChangePasswordActionSuccess['payload']) => ChangePasswordActionSuccess;
    FAIL: (error: ChangePasswordActionFail['error']) => ChangePasswordActionFail;
  };
}

export interface ChangePasswordActionSuccess extends GenericApiActionSuccess {
  type: UserActionTypes.CHANGE_PASSWORD__SUCCESS;
  payload: AxiosResponse<WrappedPasswordResetResponseVm>;
}

export interface ChangePasswordActionFail extends GenericApiActionFail {
  type: UserActionTypes.CHANGE_PASSWORD__FAIL;
  error: AxiosError<WrappedPasswordResetResponseVm>;
}

export type UserActions =
  | MarkUserAsLoggedInAction
  | ValidateCsrfTokenActionStart
  | ValidateCsrfTokenActionSuccess
  | ValidateCsrfTokenActionFail
  | CheckNewUserEmailActionStart
  | CheckNewUserEmailActionSuccess
  | CheckNewUserEmailActionFail
  | LogInUserActionStart
  | LogInUserActionSuccess
  | LogInUserActionFail
  | LogOutUserActionStart
  | LogInUserAsAdminActionStart
  | LogInUserAsAdminActionSuccess
  | LogInUserAsAdminActionFail
  | LogOutUserActionSuccess
  | RegisterNewHomeownerUserActionStart
  | RegisterNewHomeownerUserActionSuccess
  | RegisterNewHomeownerUserActionFail
  | RegisterExistingHomeownerUserActionStart
  | RegisterExistingHomeownerUserActionSuccess
  | RegisterExistingHomeownerUserActionFail
  | VerifyEmailAddressActionStart
  | VerifyEmailAddressActionSuccess
  | VerifyEmailAddressActionFail
  | ResendVerificationEmailActionStart
  | ResendVerificationEmailActionSuccess
  | ResendVerificationEmailActionFail
  | FetchAccountInfoActionStart
  | FetchAccountInfoActionSuccess
  | FetchAccountInfoActionFail
  | RequestPasswordResetEmailActionStart
  | RequestPasswordResetEmailActionSuccess
  | RequestPasswordResetEmailActionFail
  | VerifyPasswordResetLinkActionStart
  | VerifyPasswordResetLinkActionSuccess
  | VerifyPasswordResetLinkActionFail
  | ChangePasswordActionStart
  | ChangePasswordActionSuccess
  | ChangePasswordActionFail;
