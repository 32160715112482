import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import merge from 'classnames';

import { Button } from 'components/generic/Button';
import { Pattern } from 'components/generic/Pattern';
import { IconErrorOutline } from 'components/icons/IconErrorOutline';
import { IconHome } from 'components/icons/IconHome';
import { IconLeaderboard } from 'components/icons/IconLeaderboard';
import { IconMoreHorizontal } from 'components/icons/IconMoreHorizontal';
import { NavigationService } from 'services/Navigation';
import { AnalyzeMode } from 'store/reducers/property/types';

import { Props } from './types';

import styles from './BottomNavigation.module.scss';

const {
  Private: {
    Account,
    Analyze,
    Dashboard,
    Referral,
    Support: { ContactUs, KnowledgeBase },
  },
} = NavigationService.ROUTES;

export const BottomNavigation = (props: Props) => {
  const location = useLocation();
  const [modalIsOpened, setModalIsOpened] = useState(false);

  if (!props.isUserLoggedIn) return null;

  const isActive = (value: string | string[]): boolean => {
    return (location.pathname === value || value.includes(location.pathname)) && !modalIsOpened;
  };

  const navigate = (pathName: string) => {
    setModalIsOpened(false);
    NavigationService.goTo(pathName);
  };

  return (
    <>
      <div className={styles.navigation} data-testid={BottomNavigation._jestTestId}>
        <button
          onClick={() => navigate(Dashboard)}
          className={merge(styles.button, { [styles.active]: isActive(Dashboard) })}
        >
          <IconHome classes={{ container: styles.icon }} />
          <span>Home</span>
        </button>

        <button
          onClick={() => {
            if (props.hasPropertyToAnalyze) {
              setModalIsOpened(false);
              NavigationService.goTo(Analyze, { state: { mode: AnalyzeMode.Day } });
            }
          }}
          className={merge(styles.button, { [styles.active]: isActive(Analyze) })}
          disabled={!props.hasPropertyToAnalyze}
        >
          <IconLeaderboard
            color={props.hasPropertyToAnalyze ? '#002e5d' : '#d0d1d1'}
            classes={{ container: merge(styles.icon, styles.mirror) }}
          />
          <span>Analyze</span>
        </button>

        <button
          className={merge(styles.button, {
            [styles.active]: isActive([ContactUs, KnowledgeBase]),
          })}
          onClick={() => navigate(KnowledgeBase)}
        >
          <IconErrorOutline classes={{ container: merge(styles.icon, styles.rotated180) }} />
          <span>Support</span>
        </button>

        <button
          className={merge(styles.button, { [styles.active]: modalIsOpened || isActive(Referral) })}
          onClick={() => setModalIsOpened(!modalIsOpened)}
        >
          <IconMoreHorizontal classes={{ container: styles.icon }} />
          <span>More</span>
        </button>
      </div>

      <Modal
        backdrop={false}
        isOpen={modalIsOpened}
        toggle={() => setModalIsOpened(!modalIsOpened)}
        wrapClassName={styles.more_modal}
        data-testid="modal"
      >
        <ModalBody>
          <div className={styles.menu}>
            <Button
              size="large"
              color="blue"
              colorSchema="filled"
              className={styles.button}
              onClick={() => navigate(Account)}
            >
              Your account
            </Button>

            {props.isEligibleForReferralProgram && (
              <Button
                size="large"
                color="blue"
                colorSchema="filled"
                className={styles.button}
                onClick={() => navigate(Referral)}
              >
                Refer a friend
              </Button>
            )}

            <Button
              size="large"
              color="blue"
              colorSchema="filled"
              className={styles.logout_button}
              loading={props.loggingUserOut}
              onClick={() => props.logOutUser()}
            >
              Sign out
            </Button>
          </div>

          <Pattern className={styles.pattern} />
        </ModalBody>
      </Modal>
    </>
  );
};

BottomNavigation._jestTestId = 'bottom-navigation';
