/* istanbul ignore file */
import React, { lazy } from 'react';
import { Navigate, Route, Routes as ReactRouterDomRoutes } from 'react-router-dom';

import { AuthRoute } from './AuthRoute';
import { PrivateRoute } from './PrivateRoute';
import { ROUTES } from './variables';

// Public
const ContactSupport = lazy(() => import('pages/Public/ContactSupport'));
const ForgotPassword = lazy(() => import('pages/Public/ForgotPassword'));
// Auth
const Welcome = lazy(() => import('pages/Auth/Welcome'));
const CheckEmailAddress = lazy(() => import('pages/Auth/Registration/CheckEmailAddress'));
const CreateAccount = lazy(() => import('pages/Auth/Registration/CreateAccount'));
const CheckYourInboxPage = lazy(() => import('pages/Auth/Registration/CheckYourInbox'));
const AdminLoginPage = lazy(() => import('pages/Auth/AdminLogin'));
const Login = lazy(() => import('pages/Auth/Login'));
// Private
const Account = lazy(() => import('pages/Private/Account'));
const Analyze = lazy(() => import('pages/Private/Analyze'));
const Dashboard = lazy(() => import('pages/Private/Dashboard'));
const PropertyRegistration = lazy(() => import('pages/Private/PropertyRegistration'));
const ContactUs = lazy(() => import('pages/Private/Support/ContactUs'));
const Referral = lazy(() => import('pages/Private/Referral'));
const KnowledgeBase = lazy(() => import('pages/Private/Support/KnowledgeBase'));
// System
const EmailVerification = lazy(() => import('pages/System/EmailVerification'));
const PasswordResetPage = lazy(() => import('pages/System/PasswordReset'));
const PageNotFound = lazy(() => import('pages/System/PageNotFound'));
const PageSessionExpired = lazy(() => import('pages/System/PageSessionExpired'));

const { Auth, Public, Private, System } = ROUTES;

export const Routes = () => (
  <ReactRouterDomRoutes>
    {/** Public routes */}
    <Route path={Public.ContactSupport} element={<ContactSupport />} />
    <Route path={Public.ForgotPassword} element={<ForgotPassword />} />
    <Route path={Public.PageSessionExpired} element={<PageSessionExpired />} />
    <Route path={Auth.LoginAsAdmin} element={<AdminLoginPage />} />

    {/** Auth routes */}
    <Route path={Auth.Home} element={<AuthRoute />}>
      <Route path={Auth.Home} element={<Welcome />} />
      <Route path={Auth.Login} element={<Login />} />
      <Route path={Auth.Register._}>
        <Route index element={<Navigate replace to={Auth.Register.CheckEmailAddress} />} />
        <Route path={Auth.Register.CheckEmailAddress} element={<CheckEmailAddress />} />
        <Route path={Auth.Register.CreateAccount} element={<CreateAccount />} />
        <Route path={Auth.Register.CheckYourInbox} element={<CheckYourInboxPage />} />
      </Route>
    </Route>

    {/** Private route */}
    <Route path={Auth.Home} element={<PrivateRoute />}>
      <Route path={Private.Dashboard} element={<Dashboard />} />
      <Route path={Private.Account} element={<Account />} />
      <Route path={Private.Analyze} element={<Analyze />} />
      <Route path={Private.PropertyRegistration} element={<PropertyRegistration />} />
      <Route path={Private.Support._}>
        <Route index element={<Navigate replace to={Private.Support.KnowledgeBase} />} />
        <Route path={Private.Support.KnowledgeBase} element={<KnowledgeBase />} />
        <Route path={Private.Support.ContactUs} element={<ContactUs />} />
      </Route>
      <Route path={Private.Referral} element={<Referral />} />
    </Route>

    {/** System route */}
    <Route path={System.EmailVerification} element={<EmailVerification />} />
    <Route path={System.PasswordReset} element={<PasswordResetPage />} />
    <Route path="*" element={<PageNotFound />} />
  </ReactRouterDomRoutes>
);
