import { PropertyController } from 'controllers/PropertyController';

import * as Types from './types';

export const newPropertyBeginRegistrationStart =
  (): Types.NewPropertyBeginRegistrationActionStart => ({
    type: Types.PropertyActionTypes.NEW_PROPERTY_BEGIN_REGISTRATION_START,
  });

export const newPropertyBeginRegistrationSuccess = (
  payload: Types.NewPropertyBeginRegistrationActionSuccess['payload'],
): Types.NewPropertyBeginRegistrationActionSuccess => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_BEGIN_REGISTRATION_SUCCESS,
  payload,
});

export const newPropertyBeginRegistrationFail = (
  error: Types.NewPropertyBeginRegistrationActionFail['error'],
): Types.NewPropertyBeginRegistrationActionFail => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_BEGIN_REGISTRATION_FAIL,
  error,
});

export const newPropertyCheckAddressStart = (
  payload: Types.NewPropertyCheckAddressActionStart['payload'],
): Types.NewPropertyCheckAddressActionStart => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_CHECK_ADDRESS_START,
  apiRequest: PropertyController.newPropertyCheckAddress,
  payload,
  followupActions: {
    SUCCESS: newPropertyCheckAddressSuccess,
    FAIL: newPropertyCheckAddressFail,
  },
});

export const newPropertyCheckAddressSuccess = (
  payload: Types.NewPropertyCheckAddressActionSuccess['payload'],
): Types.NewPropertyCheckAddressActionSuccess => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_CHECK_ADDRESS_SUCCESS,
  payload,
});

export const newPropertyCheckAddressFail = (
  error: Types.NewPropertyCheckAddressActionFail['error'],
): Types.NewPropertyCheckAddressActionFail => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_CHECK_ADDRESS_FAIL,
  error,
});

export const newPropertySaveDetailsStart = (
  payload: Types.NewPropertySaveDetailsActionStart['payload'],
): Types.NewPropertySaveDetailsActionStart => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_SAVE_DETAILS_START,
  payload,
});

export const newPropertySaveDetailsSuccess = (
  payload: Types.NewPropertySaveDetailsActionSuccess['payload'],
): Types.NewPropertySaveDetailsActionSuccess => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_SAVE_DETAILS_SUCCESS,
  payload,
});

export const newPropertySaveDetailsFail = (
  error: Types.NewPropertySaveDetailsActionFail['error'],
): Types.NewPropertySaveDetailsActionFail => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_SAVE_DETAILS_FAIL,
  error,
});

export const newPropertyAddInverterStart = (
  payload: Types.NewPropertyAddInvertersActionStart['payload'],
): Types.NewPropertyAddInvertersActionStart => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_ADD_INVERTERS_START,
  payload,
});

export const newPropertyAddInverterSuccess = (
  payload: Types.NewPropertyAddInvertersActionSuccess['payload'],
): Types.NewPropertyAddInvertersActionSuccess => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_ADD_INVERTERS_SUCCESS,
  payload,
});

export const newPropertyAddInverterFail = (
  error: Types.NewPropertyAddInvertersActionFail['error'],
): Types.NewPropertyAddInvertersActionFail => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_ADD_INVERTERS_FAIL,
  error,
});

export const newPropertyResetRegistrationStart = (
  payload: Types.NewPropertyResetRegistrationActionStart['payload'],
): Types.NewPropertyResetRegistrationActionStart => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_RESET_REGISTRATION_START,
  apiRequest: PropertyController.newPropertyResetRegistration,
  payload,
  followupActions: {
    SUCCESS: newPropertyResetRegistrationSuccess,
    FAIL: newPropertyResetRegistrationFail,
  },
});

export const newPropertyResetRegistrationSuccess = (
  payload: Types.NewPropertyResetRegistrationActionSuccess['payload'],
): Types.NewPropertyResetRegistrationActionSuccess => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_RESET_REGISTRATION_SUCCESS,
  payload,
});

export const newPropertyResetRegistrationFail = (
  error: Types.NewPropertyResetRegistrationActionFail['error'],
): Types.NewPropertyResetRegistrationActionFail => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_RESET_REGISTRATION_FAIL,
  error,
});

export const newPropertyFinishRegistrationStart = (
  payload: Types.NewPropertyFinishRegistrationActionStart['payload'],
): Types.NewPropertyFinishRegistrationActionStart => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_FINISH_REGISTRATION_START,
  payload,
});

export const newPropertyFinishRegistrationSuccess = (
  payload: Types.NewPropertyFinishRegistrationActionSuccess['payload'],
): Types.NewPropertyFinishRegistrationActionSuccess => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_FINISH_REGISTRATION_SUCCESS,
  payload,
});

export const newPropertyFinishRegistrationFail = (
  error: Types.NewPropertyFinishRegistrationActionFail['error'],
): Types.NewPropertyFinishRegistrationActionFail => ({
  type: Types.PropertyActionTypes.NEW_PROPERTY_FINISH_REGISTRATION_FAIL,
  error,
});

export const checkInverterSerialNumberStart = (
  payload: Types.CheckInverterSerialNumberActionStart['payload'],
): Types.CheckInverterSerialNumberActionStart => ({
  type: Types.PropertyActionTypes.CHECK_INVERTER_SERIAL_NUMBER_START,
  apiRequest: PropertyController.checkInverterSerialNumber,
  payload,
  followupActions: {
    SUCCESS: checkInverterSerialNumberSuccess,
    FAIL: checkInverterSerialNumberFail,
  },
});

export const checkInverterSerialNumberSuccess = (
  payload: Types.CheckInverterSerialNumberActionSuccess['payload'],
): Types.CheckInverterSerialNumberActionSuccess => ({
  type: Types.PropertyActionTypes.CHECK_INVERTER_SERIAL_NUMBER_SUCCESS,
  payload,
});

export const checkInverterSerialNumberFail = (
  error: Types.CheckInverterSerialNumberActionFail['error'],
): Types.CheckInverterSerialNumberActionFail => ({
  type: Types.PropertyActionTypes.CHECK_INVERTER_SERIAL_NUMBER_FAIL,
  error,
});

export const fetchAllInverterTypesStart = (): Types.FetchAllInverterTypesActionStart => ({
  type: Types.PropertyActionTypes.FETCH_ALL_INVERTER_TYPES__START,
  apiRequest: PropertyController.fetchAllInverterTypes,
  followupActions: {
    SUCCESS: fetchAllInverterTypesSuccess,
    FAIL: fetchAllInverterTypesFail,
  },
});

export const fetchAllInverterTypesSuccess = (
  payload: Types.FetchAllInverterTypesActionSuccess['payload'],
): Types.FetchAllInverterTypesActionSuccess => ({
  type: Types.PropertyActionTypes.FETCH_ALL_INVERTER_TYPES__SUCCESS,
  payload,
});

export const fetchAllInverterTypesFail = (
  error: Types.FetchAllInverterTypesActionFail['error'],
): Types.FetchAllInverterTypesActionFail => ({
  type: Types.PropertyActionTypes.FETCH_ALL_INVERTER_TYPES__FAIL,
  error,
});

export const fetchAllHomeownerPropertiesStart =
  (): Types.FetchAllHomeownerPropertiesActionStart => ({
    type: Types.PropertyActionTypes.FETCH_ALL_HOMEOWNER_PROPERTIES__START,
    apiRequest: PropertyController.fetchAllHomeownerProperties,
    followupActions: {
      SUCCESS: fetchAllHomeownerPropertiesSuccess,
      FAIL: fetchAllHomeownerPropertiesFail,
    },
  });

export const fetchAllHomeownerPropertiesSuccess = (
  payload: Types.FetchAllHomeownerPropertiesActionSuccess['payload'],
): Types.FetchAllHomeownerPropertiesActionSuccess => ({
  type: Types.PropertyActionTypes.FETCH_ALL_HOMEOWNER_PROPERTIES__SUCCESS,
  payload,
});

export const fetchAllHomeownerPropertiesFail = (
  error: Types.FetchAllHomeownerPropertiesActionFail['error'],
): Types.FetchAllHomeownerPropertiesActionFail => ({
  type: Types.PropertyActionTypes.FETCH_ALL_HOMEOWNER_PROPERTIES__FAIL,
  error,
});

export const fetchPropertyDashboardProductionDataStart =
  (): Types.FetchPropertyDashboardProductionDataActionStart => ({
    type: Types.PropertyActionTypes.FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__START,
  });

export const fetchPropertyDashboardProductionDataSuccess = (
  payload: Types.FetchPropertyDashboardProductionDataActionSuccess['payload'],
): Types.FetchPropertyDashboardProductionDataActionSuccess => ({
  type: Types.PropertyActionTypes.FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__SUCCESS,
  payload,
});

export const fetchPropertyDashboardProductionDataFail = (
  error: Types.FetchPropertyDashboardProductionDataActionFail['error'],
): Types.FetchPropertyDashboardProductionDataActionFail => ({
  type: Types.PropertyActionTypes.FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__FAIL,
  error,
});

export const updateAppliancesStart = (
  payload: Types.UpdateAppliancesActionStart['payload'],
): Types.UpdateAppliancesActionStart => ({
  type: Types.PropertyActionTypes.UPDATE_APPLIANCES__START,
  apiRequest: PropertyController.updateAppliances,
  payload,
  followupActions: {
    SUCCESS: updateAppliancesSuccess,
    FAIL: updateAppliancesFail,
  },
});

export const updateAppliancesSuccess = (
  payload: Types.UpdateAppliancesActionSuccess['payload'],
): Types.UpdateAppliancesActionSuccess => ({
  type: Types.PropertyActionTypes.UPDATE_APPLIANCES__SUCCESS,
  payload,
});

export const updateAppliancesFail = (
  error: Types.UpdateAppliancesActionFail['error'],
): Types.UpdateAppliancesActionFail => ({
  type: Types.PropertyActionTypes.UPDATE_APPLIANCES__FAIL,
  error,
});

export const changeAnalyzeGraphSettingsStart = (
  payload: Types.ChangeAnalyzeGraphSettingsActionStart['payload'],
): Types.ChangeAnalyzeGraphSettingsActionStart => ({
  type: Types.PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__START,
  payload,
});

export const changeAnalyzeGraphSettingsInProgress = (
  payload: Types.ChangeAnalyzeGraphSettingsInProgress['payload'],
): Types.ChangeAnalyzeGraphSettingsInProgress => ({
  type: Types.PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__IN_PROGRESS,
  payload,
});

export const changeAnalyzeGraphSettingsSuccess = (
  payload: Types.ChangeAnalyzeGraphSettingsActionSuccess['payload'],
): Types.ChangeAnalyzeGraphSettingsActionSuccess => ({
  type: Types.PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__SUCCESS,
  payload,
});

export const changeAnalyzeGraphSettingsFail = (
  error: Types.ChangeAnalyzeGraphSettingsActionFail['error'],
): Types.ChangeAnalyzeGraphSettingsActionFail => ({
  type: Types.PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__FAIL,
  error,
});
