/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ExistingHomeOwnerRegistrationFinishStatus {
  Success = 0,
  EmailOrSecretIsInvalid = 1,
  SecretIsExpired = 2,
  HomeOwnerIsInactive = 3,
  HomeOwnerIsAlreadyRegistered = 4,
}
