/* istanbul ignore file */
import { connect } from 'react-redux';

import { RootState } from 'store';
import { selectRequestStatus } from 'store/reducers/api-requests/selectors';
import { selectCurrentlySelectedProperty } from 'store/reducers/property/selectors';
import { logOutUserStart } from 'store/reducers/user/actions';
import {
  selectIfUserIsLoggedIn,
  selectIsEligibleForReferralProgram,
} from 'store/reducers/user/selectors';
import { UserActionTypes } from 'store/reducers/user/types';

import { Header as Component } from './Header';

export const mapStateToProps = (state: RootState) => ({
  isUserLoggedIn: selectIfUserIsLoggedIn(state),
  loggingUserOut: selectRequestStatus(state, UserActionTypes.LOG_OUT__START),
  hasPropertyToAnalyze: Boolean(selectCurrentlySelectedProperty(state)),
  isEligibleForReferralProgram: selectIsEligibleForReferralProgram(state),
});

export const mapActionsToProps = {
  logOutUser: logOutUserStart,
};

export const Header = connect(mapStateToProps, mapActionsToProps)(Component);
