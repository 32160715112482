import { RootState } from 'store';

import { UserActionTypes } from './types';

export const selectIfUserIsLoggedIn = (state: RootState) => {
  return state.user.loggedIn;
};

export const selectNewUserEmailStatus = (state: RootState) => {
  return state.user[UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__SUCCESS];
};

export const selectLogInStatus = (state: RootState) => {
  return state.user[UserActionTypes.LOG_IN__SUCCESS];
};

export const selectEmailVerificationStatus = (state: RootState) => {
  return state.user[UserActionTypes.VERIFY_EMAIL_ADDRESS__SUCCESS]?.verificationStatus;
};

export const selectExistingHomeownerRegistrationStatus = (state: RootState) => {
  return state.user[UserActionTypes.REGISTER_EXISTING_HOMEOWNER__SUCCESS]?.registrationStatus;
};

export const selectAccountInfo = (state: RootState) => {
  return state.user.accountInfo;
};

export const selectSendPasswordResetEmailStatus = (state: RootState) => {
  return state.user.passwordChange[UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__SUCCESS];
};

export const selectVerifyPasswordResetLinkStatus = (state: RootState) => {
  return state.user.passwordChange[UserActionTypes.VERIFY_PASSWORD_RESET_LINK__SUCCESS];
};

export const selectChangePasswordStatus = (state: RootState) => {
  return state.user.passwordChange[UserActionTypes.CHANGE_PASSWORD__SUCCESS];
};

export const selectResendVerificationEmailResponse = (state: RootState) => {
  return state.user[UserActionTypes.RESEND_VERIFICATION_EMAIL__SUCCESS];
};

export const selectIsEligibleForReferralProgram = (state: RootState) => {
  return Boolean(state.property.selected && state.user.accountInfo?.homeOwnerInfo.isEmailVerified);
};
