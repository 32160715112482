/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EmailAddressStatus {
  MultipleHomeOwnersExist = 0,
  VacantEmailAddress = 1,
  NotActivatedHomeOwnerExists = 2,
  RegistrationInProgress = 3,
  ActivatedHomeOwnerExists = 4,
  DeactivatedHomeOwnerExists = 5,
}
