import React, { useCallback, useEffect } from 'react';
import { Button } from '@gafe/common-ui';
import classNames from 'classnames';

import { IconPillPrivacy } from '../../icons/IconPillPrivacy';

import { GAF_LINKS } from './constants';

import styles from './Footer.module.scss';

export const Footer = () => {
  const goTo = useCallback((link: string) => {
    window.open(link, '_blank', 'noreferrer');
  }, []);

  useEffect(() => {
    typeof window.OneTrust !== 'undefined' && window.OneTrust.initializeCookiePolicyHtml(true);
  }, []);

  return (
    <footer className={styles.wrapper} data-testid={Footer._jestTestId}>
      <div className={styles.links_wrapper}>
        <div className={styles.first_row}>
          <Button
            size="small"
            className={styles.button}
            colorSchema="primary"
            variant="text"
            onClick={() => goTo(GAF_LINKS.PRIVACY_POLICY)}
          >
            Privacy Policy
          </Button>
          <Button
            className={styles.button}
            size="small"
            colorSchema="primary"
            variant="text"
            onClick={() => goTo(GAF_LINKS.CALIFORNIA_PRIVACY)}
          >
            California Privacy
          </Button>
          <Button
            className={styles.button}
            size="small"
            colorSchema="primary"
            variant="text"
            onClick={() => goTo(GAF_LINKS.TERMS_OF_SERVICE)}
          >
            Terms Of Use
          </Button>
        </div>

        <div className={styles.second_row}>
          <Button
            className={styles.button}
            size="small"
            colorSchema="primary"
            variant="text"
            onClick={() => goTo(GAF_LINKS.APPLICANT_NOTICE)}
          >
            Applicant Notice
          </Button>
          <IconPillPrivacy
            classes={{ container: styles.privacy_icon }}
            onClick={() => goTo(GAF_LINKS.YOUR_PRIVACY_CHOICES)}
          />
          <Button
            className={styles.button}
            size="small"
            colorSchema="primary"
            variant="text"
            onClick={() => goTo(GAF_LINKS.YOUR_PRIVACY_CHOICES)}
          >
            Your Privacy Choices
          </Button>
          <Button
            className={classNames(
              'ot-sdk-show-settings',
              styles.button,
              styles.manage_cookies_button,
            )}
            size="small"
            colorSchema="primary"
            variant="text"
          >
            Manage Your Cookie Preferences
          </Button>
        </div>
      </div>
    </footer>
  );
};

Footer._jestTestId = 'footer';
