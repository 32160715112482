/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EnergyProductionTimePeriod {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
  Lifetime = 'lifetime',
}
