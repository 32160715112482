import { call, put } from 'redux-saga/effects';

import { BrowserStorageService } from 'services/BrowserStorage';
import { InactivityMonitoringService } from 'services/InactivityMonitoring';
import { NavigationService } from 'services/Navigation';
import * as userActions from 'store/reducers/user/actions';

import { handleErrorCaughtBySaga } from '../helpers/handleErrorCaughtBySaga';

export function* InactivityMonitoringSaga() {
  try {
    // Setup the user inactivity monitor
    yield call(InactivityMonitoringService.start, {
      maxIdleTime: 1000 * 60 * 60, // 1 hour
      checkInactivityEvery: 1000 * 60, // 1 minute
      onIdleTimeEndCallback: () => {
        BrowserStorageService.removeAll();
        BrowserStorageService.removeAll(true);
        userActions.logOutUserSuccess();
        NavigationService.goTo(NavigationService.ROUTES.Public.PageSessionExpired);
      },
    });
    yield put(userActions.inactivityMonitoringSuccess());
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      userActions.inactivityMonitoringFail,
      'GlobalSaga - InactivityMonitoringSaga',
    );
  }
}
