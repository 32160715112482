import axios from 'axios';
import { put } from 'redux-saga/effects';

import { BrowserStorageService } from 'services/BrowserStorage';
import { EnvironmentService } from 'services/Environment';
import { setUnexpectedError } from 'store/reducers/global/actions';
import { logOutUserStart } from 'store/reducers/user/actions';
import { GenericApiActionFail, SagaHelperExitCode } from 'types';

type Action = (error: any) => GenericApiActionFail;
export function* handleErrorCaughtBySaga(error: any, action: Action, caller: string) {
  // Ignore axios request cancellation
  if (axios.isCancel(error)) return SagaHelperExitCode.STOP;

  // Log caught error using browser console only in DEV environment
  if (EnvironmentService.isDEV()) console.error(caller, error);

  // Process unknown or internal server errors
  if (!error.response || error.response.status >= 500) {
    yield put(setUnexpectedError(error));

    return SagaHelperExitCode.STOP;
  }

  // Call "FAIL" action with caught error
  yield put(action(error));

  switch (error.response.status) {
    case 401:
      // Avoid user logout when session is still valid
      // user  inactivity monitoring will perform this logout instead
      // otherwise, logging out the user must be performed here
      if (BrowserStorageService.csrfToken.get() || BrowserStorageService.csrfToken.get(true)) {
        // User is not authorized - log them out
        yield put(logOutUserStart(true));
      }

      return SagaHelperExitCode.STOP;
  }

  return SagaHelperExitCode.CONTINUE;
}
