import isNumber from 'lodash/isNumber';

import { BROWSER_STORAGE_KEY } from 'variables';

import { BrowserStorageService as Service, State } from './types';

export const BrowserStorageService: Service = new (class {
  private storage: Storage = localStorage || sessionStorage;
  private storageKey = BROWSER_STORAGE_KEY;

  private getStorage = (forceSessionStorage = false) => {
    return forceSessionStorage ? sessionStorage : this.storage;
  };

  private state = {
    // Get all the data
    get: (forceSessionStorage = false): State => {
      try {
        const state = this.getStorage(forceSessionStorage).getItem(this.storageKey);

        return state ? JSON.parse(state) : {};
      } catch {
        return {};
      }
    },

    set: (newState: State, forceSessionStorage = false) => {
      const currentState = this.state.get(forceSessionStorage);
      const storage = this.getStorage(forceSessionStorage);

      storage.setItem(this.storageKey, JSON.stringify({ ...currentState, ...newState }));
    },

    // Remove specific item from the data
    removeItem: (itemKey: keyof State, forceSessionStorage = false) => {
      const state = this.state.get(forceSessionStorage);

      state[itemKey] = undefined;
      this.state.set(state, forceSessionStorage);
    },

    // Clear all the data
    clear: (forceSessionStorage = false, forceClear = false) => {
      const storage = this.getStorage(forceSessionStorage);
      const xReCaptchaId = this.state.get(true).xReCaptchaId;

      const getStorageValue = () => {
        if (forceClear) {
          return '{}';
        }

        return JSON.stringify({ xReCaptchaId });
      };

      storage.setItem(this.storageKey, getStorageValue());
    },
  };

  // Clear all the application data from a browser storage
  readonly removeAll: Service['removeAll'] = (forceSessionStorage = false, forceClear = false) =>
    this.state.clear(forceSessionStorage, forceClear);

  readonly removeItems: Service['removeItems'] = (
    itemKeys: Array<keyof State>,
    forceSessionStorage = false,
  ) => {
    const state = this.state.get(forceSessionStorage);

    itemKeys.forEach(itemKey => (state[itemKey] = undefined));
    this.state.set(state, forceSessionStorage);
  };

  // ---------------------------------------------
  // Below are the management objects for each
  // supported item in the application data object
  // ---------------------------------------------

  readonly csrfToken: Service['csrfToken'] = {
    get: (forceSessionStorage = false) => this.state.get(forceSessionStorage).csrfToken,
    set: (value: State['csrfToken'], forceSessionStorage = false) => {
      this.state.set({ csrfToken: value }, forceSessionStorage);
    },
    remove: (forceSessionStorage = false) => {
      this.state.removeItem('csrfToken', forceSessionStorage);
    },
  };

  readonly xReCaptchaId: Service['xReCaptchaId'] = {
    get: (forceSessionStorage = true) => this.state.get(forceSessionStorage).xReCaptchaId,
    set: (value: State['xReCaptchaId'], forceSessionStorage = true) => {
      this.state.set({ xReCaptchaId: value }, forceSessionStorage);
    },
    remove: (forceSessionStorage = true) => {
      this.state.removeItem('xReCaptchaId', forceSessionStorage);
    },
  };

  readonly postAuthRedirect: Service['postAuthRedirect'] = {
    get: () => this.state.get().postAuthRedirect,
    set: (route?: State['postAuthRedirect']) => {
      const { pathname, search } = route || {};

      this.state.set({
        postAuthRedirect: {
          pathname: pathname || window.location.pathname,
          search: search || window.location.search,
        },
      });
    },
    remove: () => this.state.removeItem('postAuthRedirect'),
  };

  readonly analyzeGraph: Service['analyzeGraph'] = {
    get: () => this.state.get().analyzeGraph,
    set: (settings: State['analyzeGraph']) => this.state.set({ analyzeGraph: settings }),
    remove: () => this.state.removeItem('analyzeGraph'),
  };

  // Story https://gafe.atlassian.net/browse/GE-5391 AC#3
  readonly selfRegStep2SubStep: Service['selfRegStep2SubStep'] = {
    get: () => this.state.get().selfRegStep2SubStep,
    set: (step: State['selfRegStep2SubStep']) => this.state.set({ selfRegStep2SubStep: step }),
    remove: () => this.state.removeItem('selfRegStep2SubStep'),
  };

  readonly lastActivity: Service['lastActivity'] = {
    get: () => {
      const timestamp = this.state.get().lastActivity;

      return timestamp && isNumber(+timestamp) ? +timestamp : null;
    },
    set: () => this.state.set({ lastActivity: Date.now() }),
    remove: () => this.state.removeItem('lastActivity'),
  };
})();
