import camelCase from 'lodash/camelCase';
import * as yup from 'yup';

import { ObjectKeys } from 'types';
import { removeNonNumeric } from 'utils/remove-non-numeric';
import { removeUselessSpaces } from 'utils/remove-useless-spaces';

export const validationRegExps = {
  // minimum 8 characters
  // at least one lowercase letter
  // at least one uppercase letter
  // at least one number
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S+]{8,}$/,
  // source: https://emailregex.com/
  email:
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  notNumeric: /^\D+$/,
  state: /^[A-Z]{2}$/,
  zipCode: /^[0-9]{5}$/,
};

const messages = {
  firstName: {
    generic: 'Please enter a valid first name',
    min2: 'The minimum first name length is 2 characters',
    max80: 'The maximum first name length is 80 characters',
    required: 'First name is required',
  },
  lastName: {
    generic: 'Please enter a valid last name',
    min2: 'The minimum last name length is 2 characters',
    max80: 'The maximum last name length is 80 characters',
    required: 'First name is required',
  },
  emailAddress: {
    generic: 'Please enter a valid email address',
    max80: 'The maximum email address length is 80 characters',
    regex: 'Please enter a valid email address',
    required: 'Email address is required',
  },
  phoneNumber: {
    generic: 'Please enter a valid phone number',
    regex: 'Phone number should contain 10 numbers',
    required: 'Phone number is required',
  },
  password: {
    generic: 'Please enter a valid password',
    min8: 'The minimum password length is 8 characters',
    regex: 'Password must contain at least 1 lowercase letter, 1 capital letter and 1 number',
    required: 'Password is required',
  },
  passwordConfirmation: {
    generic: 'The passwords do not match',
  },
  streetAddress: {
    generic: 'Please enter a valid address',
    min4: 'The minimum address length is 4 characters',
    max160: 'The maximum address length is 120 characters',
    required: 'Address is required',
  },
  city: {
    generic: 'Please enter a valid city',
    min2: 'The minimum city length is 2 characters',
    max80: 'The maximum city length is 80 characters',
    required: 'City is required',
  },
  state: {
    generic: 'Please enter a valid state code',
    required: 'State is required',
  },
  zipCode: {
    generic: 'Please enter a valid ZIP code',
    required: 'ZIP code is required',
  },
  systemSize: {
    generic: 'Please enter a valid system size',
    notLess: 'The minimum system size is 0.30kw',
    notMore: 'The maximum system size is 999.99kw',
    required: 'System size is required',
  },
  subject: {
    generic: 'Please enter a valid subject',
    min1: 'The minimum subject length is 1 character',
    max225: 'The maximum subject length is 225 characters',
  },
  description: {
    generic: 'Please enter a valid message',
    min1: 'The minimum message length is 1 character',
    max32000: 'The maximum message length is 32000 characters',
  },
};

const trimmedString = yup.string().transform(removeUselessSpaces);

const rules = {
  firstName: trimmedString.min(2, messages.firstName.generic).max(80, messages.firstName.generic),
  lastName: trimmedString.min(2, messages.lastName.generic).max(80, messages.lastName.generic),
  emailAddress: yup
    .string()
    .transform(value => value.trim().toLowerCase())
    .max(80, messages.emailAddress.generic)
    .matches(validationRegExps.email, messages.emailAddress.generic),
  phoneNumber: yup.string().transform(removeNonNumeric).length(10, messages.phoneNumber.generic),
  password: yup
    .string()
    .min(8, messages.password.generic)
    .matches(validationRegExps.password, messages.password.generic),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], messages.passwordConfirmation.generic),
  streetAddress: trimmedString
    .min(4, messages.streetAddress.min4)
    .max(120, messages.streetAddress.max160)
    .required(messages.streetAddress.required),
  city: trimmedString
    .min(2, messages.city.min2)
    .max(80, messages.city.max80)
    .required(messages.city.required),
  state: trimmedString
    .matches(validationRegExps.state, messages.state.generic)
    .required(messages.state.required),
  zipCode: trimmedString
    .matches(validationRegExps.zipCode, messages.zipCode.generic)
    .required(messages.zipCode.required),
  systemSize: yup
    .number()
    .moreThan(0.29, messages.systemSize.notLess)
    .lessThan(1000, messages.systemSize.notMore)
    .required(messages.systemSize.required),
  subject: trimmedString.min(1, messages.subject.min1).max(225, messages.subject.max225),
  description: yup
    .string()
    .min(1, messages.description.min1)
    .max(32000, messages.description.max32000),
};

const rulesWithRequired = (Object.keys(rules) as ObjectKeys<typeof rules>).reduce(
  (_, field) => ({ ..._, [field]: rules[field].required(messages[field].generic) }),
  {} as typeof rules,
);

export const convertBackendMessagesToFrontend = <T = Record<string, string[]>>(data: T): T =>
  // @ts-ignore
  (Object.keys(data).map(camelCase) as ObjectKeys<T>).reduce(
    (result, key) =>
      key in messages
        ? { ...result, [key]: messages[key as keyof typeof messages].generic }
        : result,
    {} as T,
  );

export const genericValidation = {
  rules,
  messages,
  rulesWithRequired,
  convertBackendMessagesToFrontend,
};
