import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { BrowserStorageService } from 'services/BrowserStorage';

import { ROUTES } from '../variables';

export const AuthRoute = () => {
  const csrfToken =
    BrowserStorageService.csrfToken.get() || BrowserStorageService.csrfToken.get(true);

  return csrfToken ? <Navigate replace to={ROUTES.Private.Dashboard} /> : <Outlet />;
};
