import React, { HTMLAttributes } from 'react';
import merge from 'classnames';

import { BottomNavigation } from 'components/layout/BottomNavigation';
import { Header } from 'components/layout/Header';

import { Footer } from '../Footer';

import { PageSectionContainer } from './Page.SectionContainer';
import { PageSpinner } from './Page.Spinner';
import { PageTitle } from './Page.Title';

import styles from './Page.module.scss';

export type Props = HTMLAttributes<HTMLDivElement> & {
  centered?: boolean;
  withHeader?: boolean;
  withNavigation?: boolean;
  withFooter?: boolean;
  className?: string;
};

export const Page = (props: Props) => {
  const {
    children,
    className,
    centered = false,
    withHeader = false,
    withNavigation = false,
    withFooter = true,
    ...divProps
  } = props;

  return (
    <>
      {withHeader && <Header withNavigation={withNavigation} />}

      <main
        data-testid={Page._jestTestId}
        {...divProps}
        className={merge(
          styles.container,
          { [styles.with_navigation]: withNavigation, [styles.centered]: centered },
          className,
        )}
      >
        {children}
      </main>

      {withNavigation && <BottomNavigation />}

      {withFooter && <Footer />}
    </>
  );
};

Page._jestTestId = 'page-container';
Page.Title = PageTitle;
Page.Spinner = PageSpinner;
Page.SectionContainer = PageSectionContainer;
