import { createBrowserHistory, History, Pathname } from 'history';

import { ROUTES } from 'router/variables';

import { NavigationService as Service, Options } from './types';

// Create a history of your choosing (we're using a browser history in this case)
const history: History = createBrowserHistory();

export class NavigationServiceClass {
  public readonly ROUTES = ROUTES;

  constructor(readonly history: History) {}

  goTo(path: Pathname, options: Options = {}): void {
    // Separate custom options from default history options
    const { urlParams, ...locationDescriptor } = options;
    let pathname = path;

    if (urlParams) {
      // Replace all the path params with their values
      // Example: given urlParams = { id: 15 | '15' } path "/users/:id" becomes "/users/15"
      Object.keys(urlParams).forEach(urlParamKey => {
        pathname = pathname.replace(`:${urlParamKey}`, `${urlParams[urlParamKey]}`);
      });
    }

    this.history.push(pathname, { ...locationDescriptor });
  }

  goBack() {
    this.history.back();
  }

  // When clearing router state your component WILL re-render
  clearState() {
    const { replace, location } = this.history;

    replace(`${location.pathname}${location.search}`);
  }
}

export const NavigationService: Service = new NavigationServiceClass(history);
