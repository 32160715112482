import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import { PropertyController } from 'controllers/PropertyController';
import { UserController } from 'controllers/UserController';
import { ROUTES } from 'router/variables';
import { BrowserStorageService } from 'services/BrowserStorage';
import * as propertyActions from 'store/reducers/property/actions';
import * as userActions from 'store/reducers/user/actions';
import { handleErrorCaughtBySaga } from 'store/sagas/helpers/handleErrorCaughtBySaga';
import { WrappedGetHomeOwnerPropertiesResponseVm, WrappedHomeOwnerAccountInfoVm } from 'types';

const excludedPages = {
  allProperties: [ROUTES.Private.Dashboard, ROUTES.Private.PropertyRegistration],
  accountInfo: [ROUTES.Private.Dashboard, ROUTES.Private.Account],
};

export function* ApplicationStartUpSaga() {
  try {
    // Stop everything if there is no accsrfcess token at all
    if (!BrowserStorageService.csrfToken.get() && !BrowserStorageService.csrfToken.get(true)) {
      yield put(userActions.validateCsrfTokenSuccess());

      return;
    }

    // Validate csrf token on Backend
    yield call(UserController.validateCsrfToken);

    // Fetch all homeowner properties (excluding some pages that do that for their needs)
    if (!excludedPages.allProperties.includes(window.location.pathname)) {
      type Response = AxiosResponse<WrappedGetHomeOwnerPropertiesResponseVm>;
      const response: Response = yield call(PropertyController.fetchAllHomeownerProperties);

      yield put(propertyActions.fetchAllHomeownerPropertiesSuccess(response));
    }

    // Fetch user info (excluding some pages that do that for their needs)
    if (!excludedPages.accountInfo.includes(window.location.pathname)) {
      type Response = AxiosResponse<WrappedHomeOwnerAccountInfoVm>;
      const response: Response = yield call(UserController.fetchAccountInfo);

      yield put(userActions.fetchAccountInfoSuccess(response));
    }

    // Finish everything
    yield put(userActions.validateCsrfTokenSuccess());
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      userActions.validateCsrfTokenFail,
      'ApplicationStartUpSaga - validateCsrfTokenFail',
    );
  }
}
