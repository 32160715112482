import { AxiosError } from 'axios';

import { AllActions, RootState } from 'store';
import { WrappedResponseVm } from 'types';

type ActionType = AllActions['type'];

export const selectRequestStatus = (state: RootState, actionType: ActionType): boolean => {
  return state.apiRequests.statuses[actionType.split(':')[0]] || false;
};

export const selectRequestError = <Error = WrappedResponseVm>(
  state: RootState,
  actionType: ActionType,
): AxiosError<Error | unknown> | null => {
  return state.apiRequests.errors[actionType.split(':')[0]] || null;
};

export const apiRequestsSelectors = {
  selectRequestStatus,
  selectRequestError,
};
