/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PasswordResetStatus {
  Success = 0,
  HomeOwnerDoesNotExist = 1,
  MultipleHomeOwnersExist = 2,
  LinkIsInvalidOrExpired = 3,
}
