export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export type AnyFunction = (...args: any[]) => any;

export type ObjectKeys<T> = Array<keyof T>;

export enum CustomGlobalExceptions {
  PageNotFound = 'PageNotFound',
}

type OneTrust = {
  initializeCookiePolicyHtml(initialize: boolean): void;
};

declare global {
  interface Window {
    OneTrust: OneTrust;
  }
}
