import { AxiosError, AxiosResponse } from 'axios';

import { SupportController } from 'controllers/SupportController';
import {
  CreateContactSupportCaseRequestVm,
  FaqArticleVm,
  GenericApiActionFail,
  GenericApiActionSuccess,
  WrappedGetFaqResponseVm,
  WrappedResponseVm,
} from 'types';

export enum SupportActionTypes {
  CREATE_CONTACT_SUPPORT_CASE__START = 'support|createContactSupportCase:start',
  CREATE_CONTACT_SUPPORT_CASE__SUCCESS = 'support|createContactSupportCase:success',
  CREATE_CONTACT_SUPPORT_CASE__FAIL = 'support|createContactSupportCase:fail',
  FETCH_KNOWLEDGE_BASE_DATA__START = 'support|fetchKnowledgeBaseData:start',
  FETCH_KNOWLEDGE_BASE_DATA__SUCCESS = 'support|fetchKnowledgeBaseData:success',
  FETCH_KNOWLEDGE_BASE_DATA__FAIL = 'support|fetchKnowledgeBaseData:fail',
}

export type SupportState = {
  knowledgeBase: {
    categories: string[];
    articles: { [categoryName: string]: FaqArticleVm[] };
  };
};

export interface CreateContactSupportCaseActionStart {
  type: SupportActionTypes.CREATE_CONTACT_SUPPORT_CASE__START;
  apiRequest: typeof SupportController.createContactSupportCase;
  payload: [CreateContactSupportCaseRequestVm];
  followupActions: {
    SUCCESS: (
      payload: CreateContactSupportCaseActionSuccess['payload'],
    ) => CreateContactSupportCaseActionSuccess;
    FAIL: (
      error: CreateContactSupportCaseActionFail['error'],
    ) => CreateContactSupportCaseActionFail;
  };
}

export interface CreateContactSupportCaseActionSuccess extends GenericApiActionSuccess {
  type: SupportActionTypes.CREATE_CONTACT_SUPPORT_CASE__SUCCESS;
  payload: AxiosResponse<WrappedResponseVm>;
}

export interface CreateContactSupportCaseActionFail extends GenericApiActionFail {
  type: SupportActionTypes.CREATE_CONTACT_SUPPORT_CASE__FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface FetchKnowledgeBaseDataActionStart {
  type: SupportActionTypes.FETCH_KNOWLEDGE_BASE_DATA__START;
  apiRequest: typeof SupportController.fetchKnowledgeBaseData;
  followupActions: {
    SUCCESS: (
      payload: FetchKnowledgeBaseDataActionSuccess['payload'],
    ) => FetchKnowledgeBaseDataActionSuccess;
    FAIL: (error: FetchKnowledgeBaseDataActionFail['error']) => FetchKnowledgeBaseDataActionFail;
  };
}

export interface FetchKnowledgeBaseDataActionSuccess extends GenericApiActionSuccess {
  type: SupportActionTypes.FETCH_KNOWLEDGE_BASE_DATA__SUCCESS;
  payload: AxiosResponse<WrappedGetFaqResponseVm>;
}

export interface FetchKnowledgeBaseDataActionFail extends GenericApiActionFail {
  type: SupportActionTypes.FETCH_KNOWLEDGE_BASE_DATA__FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export type SupportActions =
  | CreateContactSupportCaseActionStart
  | CreateContactSupportCaseActionSuccess
  | CreateContactSupportCaseActionFail
  | FetchKnowledgeBaseDataActionStart
  | FetchKnowledgeBaseDataActionSuccess
  | FetchKnowledgeBaseDataActionFail;
