import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Page } from 'components/layout/Page';
import { Routes } from 'router';
import { BrowserStorageService } from 'services/BrowserStorage';
import { InactivityMonitoringService } from 'services/InactivityMonitoring';
import { NavigationService } from 'services/Navigation';

import { Props } from './types';

export const Wrapper = (props: Props) => {
  const { applicationIsLoading, logOutUser, startInactivityMonitoring, validateCsrfToken } = props;
  const location = useLocation();
  const isAuthenticated = useMemo(
    () => BrowserStorageService.csrfToken.get() || BrowserStorageService.csrfToken.get(true),
    [BrowserStorageService.csrfToken.get()],
  );

  useEffect(() => {
    validateCsrfToken();

    // Story https://gafe.atlassian.net/browse/GE-5391 AC#3
    if (location.pathname !== NavigationService.ROUTES.Private.PropertyRegistration) {
      BrowserStorageService.selfRegStep2SubStep.remove();
    }
  }, []);

  const logoutHandler = () => {
    if (!location?.state?.state?.isRedirectedFromAccountRegistration) {
      logOutUser();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      startInactivityMonitoring();
      window.addEventListener('storage', logoutHandler);
    }

    return () => {
      window.removeEventListener('storage', logoutHandler);
      InactivityMonitoringService.stop();
    };
  }, [isAuthenticated]);

  if (applicationIsLoading) {
    return <Page.Spinner />;
  }

  return <Routes />;
};
