import { DateTime } from 'luxon';

import { EnergyProductionService } from 'services/EnergyProductionService';
import { RootState } from 'store';
import { selectRequestStatus } from 'store/reducers/api-requests/selectors';

import { AnalyzeMode, PropertyActionTypes } from './types';

export const selectPropertyForRegistration = (state: RootState) => {
  return state.property.registration.property;
};

export const selectNewPropertyAddressCheckResult = (state: RootState) => {
  return state.property.registration.addressStatus;
};

export const selectCurrentlySelectedProperty = (state: RootState) => {
  return state.property.selected;
};

export const selectPropertyDashboardPageIsLoading = (state: RootState) => {
  if (state.property.dashboard.lastUpdatedAt) return false;

  return selectRequestStatus(
    state,
    PropertyActionTypes.FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__START,
  );
};

export const selectPropertyDashboardData = (state: RootState) => {
  return state.property.dashboard;
};

export const selectAnalyzeGraphSettings = (state: RootState) => {
  return state.property.analyze;
};

export const selectAnalyzeGraphCalendarTitle = (state: RootState) => {
  // No selected property === no analyze graph title
  if (!state.property.selected) return '';

  const {
    selected: { timeZone },
    analyze: { mode, startTimestamp, endTimestamp },
  } = state.property;

  // Not enough data === no analyze graph title
  if (!(mode && timeZone && startTimestamp && endTimestamp)) return '';

  const format = (timestamp: string, format: string) => {
    return EnergyProductionService.convert.isoToFormat(timestamp, timeZone, format);
  };

  // Format dates base on graph mode
  switch (mode) {
    case AnalyzeMode.Day:
      return format(startTimestamp, 'EEEE, MM/dd/yy');

    case AnalyzeMode.Week:
      // To request production data from December 5 up to December 11, year 2021
      // we need set startTimestamp equal to start of December 5 and
      // endTimestamp equal to start of December 12 in the request payload.
      // That is why we will subtract 1 second from the endTimestamp bringing it
      // back to the actual end of the week.

      return `${format(startTimestamp, 'MMMM d')} - ${DateTime.fromISO(endTimestamp)
        .setZone(timeZone)
        .minus({ seconds: 1 })
        .toFormat('MMMM d, yyyy')}`;

    case AnalyzeMode.Month:
      return format(startTimestamp, 'MMMM yyyy');

    case AnalyzeMode.Year:
      return format(startTimestamp, 'yyyy');

    default:
      return '';
  }
};

export const selectAnalyzeGraphTotalProduction = (state: RootState) => {
  // No selected property === no total production data
  if (!state.property.selected) return { title: '', value: 0 };

  const {
    selected: { timeZone },
    analyze: { mode, startTimestamp, energyProduction },
  } = state.property;

  // Not enough data === no total production data
  if (!(mode && timeZone && startTimestamp && energyProduction)) {
    return { title: '', value: 0 };
  }

  const value = EnergyProductionService.getEnergyValue.total(energyProduction || [], 0);
  const format = (timestamp: string, format: string) => {
    return EnergyProductionService.convert.isoToFormat(timestamp, timeZone, format);
  };

  switch (mode) {
    case AnalyzeMode.Day:
      return { value, title: `on ${format(startTimestamp, 'MM/dd/yy')}` };

    case AnalyzeMode.Week:
      return { value, title: `on week of ${format(startTimestamp, 'MMMM d')}` };

    case AnalyzeMode.Month:
      return { value, title: `in ${format(startTimestamp, 'MMMM')}` };

    case AnalyzeMode.Year:
      return { value, title: `in ${format(startTimestamp, 'yyyy')}` };

    default:
      return { title: '', value: 0 };
  }
};

export const selectAllProperties = (state: RootState) => {
  return state.property.all;
};

export const selectInverterManufacturers = (state: RootState) => {
  return state.property.registration.inverterManufacturers;
};

export const selectInvertersByManufacturer = (state: RootState) => {
  return state.property.registration.invertersByManufacturer;
};
