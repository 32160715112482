/* istanbul ignore file */
import { connect } from 'react-redux';

import { RootState } from 'store';
import { selectRequestStatus } from 'store/reducers/api-requests/selectors';
import {
  inactivityMonitoringStart,
  logOutUserStart,
  validateCsrfTokenStart,
} from 'store/reducers/user/actions';
import { UserActionTypes } from 'store/reducers/user/types';

import { Wrapper as Component } from './Wrapper';

export const mapStateToProps = (state: RootState) => ({
  applicationIsLoading: selectRequestStatus(state, UserActionTypes.VALIDATE_CSRF_TOKEN__START),
});

export const mapActionsToProps = {
  validateCsrfToken: validateCsrfTokenStart,
  startInactivityMonitoring: inactivityMonitoringStart,
  logOutUser: logOutUserStart,
};

export const Wrapper = connect(mapStateToProps, mapActionsToProps)(Component);
