import produce from 'immer';

import { BrowserStorageService } from 'services/BrowserStorage';
import { AllActions } from 'store';

import { UserActionTypes, UserState as State } from './types';

export const initialState: State = {
  loggedIn: Boolean(
    BrowserStorageService.csrfToken.get() || BrowserStorageService.csrfToken.get(true),
  ),
  accountInfo: null,
  passwordChange: {
    [UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__SUCCESS]: undefined,
    [UserActionTypes.VERIFY_PASSWORD_RESET_LINK__SUCCESS]: undefined,
    [UserActionTypes.CHANGE_PASSWORD__SUCCESS]: undefined,
  },
  [UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__SUCCESS]: undefined,
  [UserActionTypes.LOG_IN__SUCCESS]: undefined,
  [UserActionTypes.VERIFY_EMAIL_ADDRESS__SUCCESS]: undefined,
  [UserActionTypes.REGISTER_EXISTING_HOMEOWNER__SUCCESS]: undefined,
};

// TODO: clear all that stuff at the same time?
// aka why would I store VERIFY_EMAIL_ADDRESS__SUCCESS data if I am trying to login?
// And vise versa. Also clear all of it when asking for the user info?

// TODO: save "action.payload.data" in all cases?
// Will be more readable in redux
// Will allow to combine cases

export const userReducer = (state: State = initialState, action: AllActions): State =>
  produce(state, (newState: State) => {
    switch (action.type) {
      case UserActionTypes.MARK_USER_AS_LOGGED_IN: {
        newState.loggedIn = true;
        break;
      }

      case UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__START: {
        newState[UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__SUCCESS] = undefined;
        break;
      }

      case UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__SUCCESS: {
        newState[action.type] = action.payload.data.emailStatus;
        break;
      }

      case UserActionTypes.LOG_IN__START: {
        newState[UserActionTypes.LOG_IN__SUCCESS] = undefined;
        break;
      }

      case UserActionTypes.LOG_IN__SUCCESS: {
        newState[action.type] = action.payload.data.logInStatus;
        break;
      }

      case UserActionTypes.VERIFY_EMAIL_ADDRESS__START: {
        newState[UserActionTypes.VERIFY_EMAIL_ADDRESS__SUCCESS] = undefined;
        break;
      }

      case UserActionTypes.VERIFY_EMAIL_ADDRESS__SUCCESS: {
        newState[action.type] = action.payload.data;
        break;
      }

      case UserActionTypes.REGISTER_EXISTING_HOMEOWNER__START: {
        newState[UserActionTypes.REGISTER_EXISTING_HOMEOWNER__SUCCESS] = undefined;
        break;
      }

      case UserActionTypes.REGISTER_EXISTING_HOMEOWNER__SUCCESS: {
        newState[action.type] = action.payload.data;
        break;
      }

      case UserActionTypes.FETCH_ACCOUNT_INFO__SUCCESS: {
        newState.accountInfo = action.payload.data.data;
        break;
      }

      case UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__SUCCESS: {
        newState.passwordChange[UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__SUCCESS] =
          action.payload.data.data.resetStatus;
        break;
      }

      case UserActionTypes.VERIFY_PASSWORD_RESET_LINK__SUCCESS: {
        newState.passwordChange[UserActionTypes.VERIFY_PASSWORD_RESET_LINK__SUCCESS] =
          action.payload.data.data.resetStatus;
        break;
      }

      case UserActionTypes.CHANGE_PASSWORD__SUCCESS: {
        newState.passwordChange[UserActionTypes.CHANGE_PASSWORD__SUCCESS] =
          action.payload.data.data.resetStatus;
        break;
      }

      case UserActionTypes.RESEND_VERIFICATION_EMAIL__SUCCESS: {
        newState[UserActionTypes.RESEND_VERIFICATION_EMAIL__SUCCESS] = action.payload.data;
        break;
      }
    }
  });
