import React from 'react';
import { useLocation } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import merge from 'classnames';

import { ApplicationLogo } from 'components/generic/ApplicationLogo';
import AssistingBanner from 'components/generic/AssistingBanner';
import { Button } from 'components/generic/Button';
import { NavigationService } from 'services/Navigation';
import { AnalyzeMode } from 'store/reducers/property/types';

import { Props } from './types';

import styles from './Header.module.scss';

const {
  Private: {
    Account,
    Analyze,
    Dashboard,
    Support: { ContactUs, KnowledgeBase },
    Referral,
  },
} = NavigationService.ROUTES;

export const Header = (props: Props) => {
  const location = useLocation();
  const isActive = (value: string | string[]): boolean => {
    return location.pathname === value || value.includes(location.pathname);
  };

  return (
    <>
      <AssistingBanner />
      <header className={styles.header} data-testid={Header._jestTestId}>
        <ApplicationLogo
          onClick={
            location.pathname === Dashboard ? () => void 0 : () => NavigationService.goTo(Dashboard)
          }
        />

        {props.withNavigation && props.isUserLoggedIn && (
          <div className={styles.navigation}>
            <button
              onClick={() => NavigationService.goTo(Dashboard)}
              className={merge(styles.navigation_button, { [styles.active]: isActive(Dashboard) })}
            >
              <span>Home</span>
              <span>Home</span>
            </button>

            <button
              className={merge(styles.navigation_button, { [styles.active]: isActive(Analyze) })}
              disabled={!props.hasPropertyToAnalyze}
              onClick={() =>
                props.hasPropertyToAnalyze &&
                NavigationService.goTo(Analyze, { state: { mode: AnalyzeMode.Day } })
              }
              data-testid="header-active"
            >
              <span>Analyze</span>
              <span>Analyze</span>
            </button>

            <button
              onClick={() => NavigationService.goTo(KnowledgeBase)}
              className={merge(styles.navigation_button, {
                [styles.active]: isActive([ContactUs, KnowledgeBase]),
              })}
            >
              <span>Support</span>
              <span>Support</span>
            </button>

            <UncontrolledDropdown>
              <DropdownToggle
                className={merge(styles.navigation_button, {
                  [styles.active]: isActive([Referral]),
                })}
              >
                <span>More</span>
                <span>More</span>
              </DropdownToggle>
              <DropdownMenu className={styles.dropdown_menu}>
                <DropdownItem onClick={() => NavigationService.goTo(Account)}>
                  Your account
                </DropdownItem>

                {props.isEligibleForReferralProgram && (
                  <DropdownItem onClick={() => NavigationService.goTo(Referral)}>
                    Refer a friend
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>

            <Button
              size="large"
              color="blue"
              colorSchema="filled"
              className={styles.logout_button}
              loading={props.loggingUserOut}
              onClick={() => props.logOutUser()}
            >
              Sign Out
            </Button>
          </div>
        )}
      </header>
    </>
  );
};

Header._jestTestId = 'application-header';
