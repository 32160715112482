import React, { HTMLAttributes, MouseEvent } from 'react';
import merge from 'classnames';

import styles from './SvgIconContainer.module.scss';

// SVGs source
// https://material.io/resources/icons/?style=baseline
type Props = HTMLAttributes<HTMLSpanElement> & {
  className?: string;
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void;
};

export const SvgIconContainer = (props: Props) => {
  const { className, ...spanProps } = props;

  return (
    <span
      data-testid={SvgIconContainer._jestTestId}
      {...spanProps}
      className={merge(styles.svg_icon, { [styles.clickable]: Boolean(props.onClick) }, className)}
    />
  );
};

SvgIconContainer._jestTestId = 'svg-icon-container';
