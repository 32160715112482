import produce from 'immer';

import { AllActions } from 'store';
import { UserActionTypes } from 'store/reducers/user/types';

import { GlobalActionTypes, GlobalState as State } from './types';

export const initialState: State = {
  unexpectedError: null,
  loggingUserOut: false,
};

export const globalReducer = (state: State = initialState, action: AllActions): State => {
  return produce(state, (newState: State) => {
    switch (action.type) {
      case GlobalActionTypes.SET_UNEXPECTED_ERROR: {
        newState.unexpectedError = action.error;
        break;
      }

      case UserActionTypes.LOG_OUT__START: {
        newState.loggingUserOut = action.payload.unauthorized;
        break;
      }

      case UserActionTypes.LOG_OUT__SUCCESS: {
        newState.loggingUserOut = false;
        break;
      }
    }
  });
};
