import React from 'react';
import { ProgressSpinner } from '@gafe/common-ui';

import Logo from './Logo.png';

import styles from './ReactSuspenseFallback.module.scss';

export const ReactSuspenseFallback = () => (
  <div className={styles.container} data-testid={ReactSuspenseFallback._jestTestId}>
    <header className={styles.header}>
      <img src={Logo} alt="GAF Energy Logo" className={styles.logo} />
    </header>

    <div className={styles.content}>
      <ProgressSpinner size="extra-large" />
    </div>
  </div>
);

ReactSuspenseFallback._jestTestId = 'suspense-fallback';
