import { produce } from 'immer';

import { BrowserStorageService } from 'services/BrowserStorage';
import { AllActions } from 'store';

import { PropertyActionTypes, PropertyState as State } from './types';

export const initialState: State = {
  registration: {
    property: null,
    addressStatus: null,
    inverterSerialNumberAlreadyInUse: null,
    inverterManufacturers: [],
    invertersByManufacturer: {},
  },
  all: [],
  selected: null,
  dashboard: {
    lastUpdatedAt: null,
    last7Days: 0,
    today: 0,
    thisMonth: 0,
    thisYear: 0,
    lifetime: 0,
  },
  analyze: BrowserStorageService.analyzeGraph.get() || {
    mode: null,
    startTimestamp: null,
    endTimestamp: null,
    energyProduction: null,
    firstRecordDate: null,
  },
};

export const propertyReducer = (state: State = initialState, action: AllActions): State =>
  produce(state, (newState: State) => {
    switch (action.type) {
      case PropertyActionTypes.NEW_PROPERTY_BEGIN_REGISTRATION_START: {
        newState.registration = initialState.registration;
        break;
      }

      case PropertyActionTypes.NEW_PROPERTY_BEGIN_REGISTRATION_SUCCESS:
      case PropertyActionTypes.NEW_PROPERTY_ADD_INVERTERS_SUCCESS: {
        newState.registration.property = action.payload;
        break;
      }

      case PropertyActionTypes.NEW_PROPERTY_CHECK_ADDRESS_START: {
        newState.registration.addressStatus = null;
        break;
      }

      case PropertyActionTypes.NEW_PROPERTY_CHECK_ADDRESS_SUCCESS: {
        const { checkStatus } = action.payload.data.data;

        newState.registration.addressStatus = checkStatus;
        break;
      }

      case PropertyActionTypes.NEW_PROPERTY_SAVE_DETAILS_SUCCESS: {
        if (newState.registration.property) {
          newState.registration.property = { ...newState.registration.property, ...action.payload };
          newState.registration.addressStatus = null;
        }
        break;
      }

      case PropertyActionTypes.NEW_PROPERTY_FINISH_REGISTRATION_SUCCESS:
        newState.registration = initialState.registration;
        break;

      case PropertyActionTypes.CHECK_INVERTER_SERIAL_NUMBER_START: {
        newState.registration.inverterSerialNumberAlreadyInUse = null;
        break;
      }

      case PropertyActionTypes.CHECK_INVERTER_SERIAL_NUMBER_SUCCESS: {
        const { isSerialNumberAlreadyInUse } = action.payload.data.data;

        newState.registration.inverterSerialNumberAlreadyInUse = isSerialNumberAlreadyInUse;
        break;
      }

      case PropertyActionTypes.FETCH_ALL_INVERTER_TYPES__SUCCESS: {
        action.payload.data.data.types.forEach(inverter => {
          // Add manufacturer to the list of all manufacturers
          if (!newState.registration.inverterManufacturers.includes(inverter.manufacturer)) {
            newState.registration.inverterManufacturers.push(inverter.manufacturer);
          }

          // Add inverter to the list of that manufacturer's inverters
          if (newState.registration.invertersByManufacturer[inverter.manufacturer]) {
            newState.registration.invertersByManufacturer[inverter.manufacturer].push(inverter);
          } else {
            newState.registration.invertersByManufacturer[inverter.manufacturer] = [inverter];
          }
        });

        break;
      }

      case PropertyActionTypes.FETCH_ALL_HOMEOWNER_PROPERTIES__SUCCESS: {
        const { properties } = action.payload.data.data;

        // Store all properties
        newState.all = properties;

        // Store the first property that is ready for production data monitoring as "selected" if:
        //  - there is no property selected at all
        //  - selected property is not present in the list anymore (got deleted for example)
        // or store "null" to mark that there is on such property
        if (!newState.selected || !properties.find(p => p.id === newState.selected!.id)) {
          newState.selected = properties.find(p => p.isReadyForEnergyProductionMonitoring) || null;
        }

        break;
      }

      case PropertyActionTypes.FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__START: {
        newState.dashboard = initialState.dashboard;
        break;
      }

      case PropertyActionTypes.FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__SUCCESS: {
        const { firstRecordDate, ...dashboardPayload } = action.payload;

        newState.dashboard = dashboardPayload;
        newState.analyze.firstRecordDate = firstRecordDate || state.analyze.firstRecordDate;
        break;
      }

      case PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__START: {
        newState.analyze.energyProduction = null;
        break;
      }

      case PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__IN_PROGRESS: {
        newState.analyze.mode = action.payload.mode;
        newState.analyze.startTimestamp = action.payload.startTimestamp;
        newState.analyze.endTimestamp = action.payload.endTimestamp;
        newState.analyze.firstRecordDate = action.payload.firstRecordDate;
        break;
      }

      case PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__SUCCESS: {
        newState.analyze.energyProduction = action.payload.energyProduction;
        break;
      }

      case PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__FAIL:
        newState.analyze.energyProduction = action.error.response?.data || null;
        break;

      case PropertyActionTypes.UPDATE_APPLIANCES__START:
        if (newState.selected) {
          newState.selected.applianceIds = action.payload[0].applianceIds;

          // TODO [IMPORTANT FOR FUTURE]:
          //  When a user will be able to select a property that they want to "look at"
          //  (aka change the "selected" value) DO NOT update the "selected" key if
          //  a user will try to select a property that is already selected. If you do
          //  - that might overwrite "applianceIds" value incorrectly!
        }

        break;
    }
  });
