/* istanbul ignore file */
import axios, { AxiosInstance } from 'axios';

import { BrowserStorageService } from 'services/BrowserStorage';
import { EnvironmentService } from 'services/Environment';

import {
  csrfTokenRequestInterceptor,
  requestCancellationRequestInterceptor,
  requestCancellationResponseInterceptor,
  REQUESTS_STORAGE,
} from './interceptors';

let BACKEND_API_ROOT_URL = EnvironmentService.getBackendAPIRootURL();

if (!BACKEND_API_ROOT_URL.endsWith('/')) {
  BACKEND_API_ROOT_URL += '/';
}

const Axios: AxiosInstance = axios.create({
  baseURL: `${BACKEND_API_ROOT_URL}api`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
  },
});

Axios.interceptors.request.use(requestCancellationRequestInterceptor(REQUESTS_STORAGE));
Axios.interceptors.response.use(requestCancellationResponseInterceptor(REQUESTS_STORAGE));
Axios.interceptors.request.use(csrfTokenRequestInterceptor(BrowserStorageService));

export { Axios };
