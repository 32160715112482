import { takeEvery, takeLatest } from '@redux-saga/core/effects';

import { PropertyActionTypes } from 'store/reducers/property/types';

import {
  changeAnalyzeGraphSettings,
  fetchPropertyDashboardProductionData,
  newPropertyAddInvertersSaga,
  newPropertyBeginRegistrationSaga,
  newPropertyFinishRegistration,
  newPropertySaveDetailsSaga,
} from './PropertyController.saga';

export { PropertyController } from './PropertyController';
export * from './types';

const {
  NEW_PROPERTY_SAVE_DETAILS_START,
  NEW_PROPERTY_ADD_INVERTERS_START,
  CHANGE_ANALYZE_GRAPH_SETTINGS__START,
  NEW_PROPERTY_BEGIN_REGISTRATION_START,
  NEW_PROPERTY_FINISH_REGISTRATION_START,
  FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__START,
} = PropertyActionTypes;

export function* PropertyControllerSagas() {
  yield takeEvery(NEW_PROPERTY_BEGIN_REGISTRATION_START, newPropertyBeginRegistrationSaga);

  yield takeEvery(NEW_PROPERTY_SAVE_DETAILS_START, newPropertySaveDetailsSaga);

  yield takeEvery(NEW_PROPERTY_ADD_INVERTERS_START, newPropertyAddInvertersSaga);

  yield takeLatest(NEW_PROPERTY_FINISH_REGISTRATION_START, newPropertyFinishRegistration);

  yield takeEvery(
    FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__START,
    fetchPropertyDashboardProductionData,
  );

  yield takeLatest(CHANGE_ANALYZE_GRAPH_SETTINGS__START, changeAnalyzeGraphSettings);
}
