/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum LogInStatus {
  Success = 0,
  EmailOrPasswordIsWrong = 1,
  InvalidOrExpiredAdminLogInSession = 2,
  Inactive = 3,
  InactiveDueFailedAttemps = 4,
}
