import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import get from 'lodash/get';
import queryString from 'query-string';

import { EnvironmentService } from 'services/Environment';

const SECRET_KEY = '85d9c952-1ee9-4e3f-8acb-7a63f011cf5e';

export const HealthCheck = () => {
  const location = useLocation();
  const [healthStatus, setHealthStatus] = useState<string>('');

  const key = get(queryString.parse(location.search, { decode: false }), 'key');

  if (key !== SECRET_KEY) {
    return null;
  }

  const BASE_URL = EnvironmentService.getBackendAPIRootURL();

  axios.get(`${BASE_URL}health/salesforce`).then(res => {
    setHealthStatus(res.data);
  });

  return <p>{healthStatus}</p>;
};
