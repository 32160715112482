import { Props, PropsWithDefault, PropsWithoutDefault } from './types';

export const addDefault = (
  props: Props,
  defaultValues: Partial<PropsWithDefault> = {},
): PropsWithoutDefault & Required<PropsWithDefault> => {
  const {
    classes = defaultValues.classes || {},
    color = defaultValues.color || '#002e5d',
    ...withoutDefault
  } = props;

  return { ...withoutDefault, classes, color };
};
