/* istanbul ignore file */
import { connect } from 'react-redux';

import { RootState } from 'store';
import { selectAccountInfo } from 'store/reducers/user/selectors';

import { AssistingBanner as Component } from './AssistingBanner';

export const mapStateToProps = (state: RootState) => ({
  accountInfo: selectAccountInfo(state),
});

export const connector = connect(mapStateToProps, null);
export const AssistingBannerContainer = connector(Component);
