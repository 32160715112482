import { AxiosResponse } from 'axios';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import { AnyAction } from 'redux';
import { all, call, delay, put } from 'redux-saga/effects';

import { handleErrorCaughtBySaga } from 'store/sagas/helpers/handleErrorCaughtBySaga';
import { GenericApiActionStart } from 'types';
import { MINIMAL_API_PROMISE_RESOLVE_TIME } from 'variables';

export function* GenericApiRequestSaga(action: GenericApiActionStart) {
  const { apiRequest, followupActions, payload = [] } = action;
  const { SUCCESS, FAIL } = followupActions;

  try {
    // Resolve API request promise
    const [response]: [AxiosResponse] = yield all([
      call(apiRequest, ...payload),
      delay(MINIMAL_API_PROMISE_RESOLVE_TIME),
    ]);

    // Call SUCCESS action with received response
    yield put(SUCCESS(response));
  } catch (error) {
    yield call(handleErrorCaughtBySaga, error, FAIL, 'GenericApiRequestSaga');
  }
}

export const GenericApiRequestSagaActionPattern = (action: AnyAction): boolean =>
  isFunction(action.apiRequest) &&
  (action.payload === undefined || isArray(action.payload)) &&
  isFunction(action.followupActions.SUCCESS) &&
  isFunction(action.followupActions.FAIL);
