import { EnvironmentService } from 'services/Environment';
import { HomeOwnerVm } from 'types';

import { ampli as ampliProd, IdentifyProperties } from './envs/prod';
import { ampli as ampliStg } from './envs/stg';

class AmpliService {
  private get isEnabled() {
    return EnvironmentService.isSTG() || EnvironmentService.isPROD();
  }

  private get module() {
    if (EnvironmentService.isPROD()) {
      return ampliProd;
    }

    return ampliStg;
  }

  public init() {
    if (!this.isEnabled) {
      return;
    }

    const loadOptions = {
      client: {
        configuration: {
          defaultTracking: true,
        },
      },
    };

    if (EnvironmentService.isPROD()) {
      ampliProd.load({
        environment: 'production',
        ...loadOptions,
      });

      return;
    }

    ampliStg.load({
      environment: 'staging',
      ...loadOptions,
    });
  }

  public setUser({
    id,
    email,
    phoneNumber,
    activatedDate,
    authenticationType,
    isEmailVerified,
    isVerificationLinkExpired,
  }: HomeOwnerVm) {
    if (!this.isEnabled) {
      return;
    }

    this.module.identify(id, {
      email,
      phoneNumber,
      activatedDate,
      authenticationType,
      isEmailVerified,
      isVerificationLinkExpired,
    } as IdentifyProperties);
  }

  public clearUser() {
    if (!this.isEnabled) {
      return;
    }

    this.module.flush();
  }
}

export const Ampli = new AmpliService();
