/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EnergyProductionInterval {
  Hourly = 'hourly',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}
