import React, { HTMLAttributes } from 'react';
import merge from 'classnames';

import styles from './Page.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  container?: boolean;
  className?: string;
};

export const PageSectionContainer = (props: Props) => {
  const { children, className, container = true, ...divProps } = props;

  return (
    <section
      data-testid={PageSectionContainer._jestTestId}
      {...divProps}
      className={merge(styles.section, { [styles.content]: container }, className)}
    >
      {children}
    </section>
  );
};

PageSectionContainer._jestTestId = 'page-section-container';
