import produce from 'immer';
import omit from 'lodash/omit';

import { AllActions } from 'store';
import { GlobalActionTypes } from 'store/reducers/global/types';
import { GenericApiActionFail } from 'types';

import { ApiRequestsState as State } from './types';

export const initialState: State = {
  statuses: {},
  errors: {},
};

export const apiRequestsReducer = (state: State = initialState, action: AllActions): State => {
  // eslint-disable-next-line consistent-return
  return produce(state, (newState: State) => {
    if (action.type === GlobalActionTypes.SET_UNEXPECTED_ERROR) {
      return initialState;
    }

    const [requestName, requestStatus] = action.type.split(':');

    switch (requestStatus) {
      case 'start': {
        newState.statuses[requestName] = true;
        newState.errors = omit(state.errors, [requestName]);
        break;
      }

      case 'success': {
        newState.statuses = omit(state.statuses, [requestName]);
        newState.errors = omit(state.errors, [requestName]);
        break;
      }

      case 'fail': {
        newState.statuses = omit(state.statuses, [requestName]);
        newState.errors[requestName] = (action as GenericApiActionFail).error;
        break;
      }
    }
  });
};
