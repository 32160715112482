import React from 'react';
import merge from 'classnames';

import { addDefault } from './helpers';
import { SvgIconContainer } from './SvgIconContainer';
import { Props } from './types';

export const IconHome = (props: Props) => {
  const { onClick, classes, color } = addDefault(props);

  return (
    <SvgIconContainer className={classes.container} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height="24px"
        width="24px"
        fill={color}
        className={merge(classes.icon)}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </svg>
    </SvgIconContainer>
  );
};
