import { AxiosError, AxiosResponse } from 'axios';

import { NewPropertyInverter, PropertyController } from 'controllers/PropertyController';
import {
  AddPropertyDetailsRequestVm,
  FinishPropertyRegistrationRequestVm,
  GenericApiActionFail,
  GenericApiActionSuccess,
  InverterTypeVm,
  Nullable,
  PropertyAddressCheckStatus,
  PropertyDetailsVm,
  PropertyVm,
  ResetPropertyRegistrationRequestVm,
  SystemEnergyVm,
  UpdatePropertyAppliancesRequestVM,
  WrappedCheckInverterSerialNumberResponseVm,
  WrappedCheckPropertyAddressResponseVm,
  WrappedFinishPropertyRegistrationResponseVm,
  WrappedGetHomeOwnerPropertiesResponseVm,
  WrappedGetInverterTypesResponseVm,
  WrappedPropertyDetailsVm,
  WrappedResponseVm,
} from 'types';

export enum PropertyActionTypes {
  NEW_PROPERTY_BEGIN_REGISTRATION_START = 'property|newPropertyBeginRegistration:start',
  NEW_PROPERTY_BEGIN_REGISTRATION_SUCCESS = 'property|newPropertyBeginRegistration:success',
  NEW_PROPERTY_BEGIN_REGISTRATION_FAIL = 'property|newPropertyBeginRegistration:fail',
  NEW_PROPERTY_CHECK_ADDRESS_START = 'property|newPropertyCheckAddress:start',
  NEW_PROPERTY_CHECK_ADDRESS_SUCCESS = 'property|newPropertyCheckAddress:success',
  NEW_PROPERTY_CHECK_ADDRESS_FAIL = 'property|newPropertyCheckAddress:fail',
  NEW_PROPERTY_SAVE_DETAILS_START = 'property|newPropertySaveDetails:start',
  NEW_PROPERTY_SAVE_DETAILS_SUCCESS = 'property|newPropertySaveDetails:success',
  NEW_PROPERTY_SAVE_DETAILS_FAIL = 'property|newPropertySaveDetails:fail',
  NEW_PROPERTY_ADD_INVERTERS_START = 'property|newPropertyAddInverters:start',
  NEW_PROPERTY_ADD_INVERTERS_SUCCESS = 'property|newPropertyAddInverters:success',
  NEW_PROPERTY_ADD_INVERTERS_FAIL = 'property|newPropertyAddInverters:fail',
  NEW_PROPERTY_RESET_REGISTRATION_START = 'property|newPropertyResetRegistration:start',
  NEW_PROPERTY_RESET_REGISTRATION_SUCCESS = 'property|newPropertyResetRegistration:success',
  NEW_PROPERTY_RESET_REGISTRATION_FAIL = 'property|newPropertyResetRegistration:fail',
  NEW_PROPERTY_FINISH_REGISTRATION_START = 'property|newPropertyFinishRegistration:start',
  NEW_PROPERTY_FINISH_REGISTRATION_SUCCESS = 'property|newPropertyFinishRegistration:success',
  NEW_PROPERTY_FINISH_REGISTRATION_FAIL = 'property|newPropertyFinishRegistration:fail',
  CHECK_INVERTER_SERIAL_NUMBER_START = 'property|checkInverterSerialNumber:start',
  CHECK_INVERTER_SERIAL_NUMBER_SUCCESS = 'property|checkInverterSerialNumber:success',
  CHECK_INVERTER_SERIAL_NUMBER_FAIL = 'property|checkInverterSerialNumber:fail',
  FETCH_ALL_INVERTER_TYPES__START = 'property|fetchAllInverterTypes:start',
  FETCH_ALL_INVERTER_TYPES__SUCCESS = 'property|fetchAllInverterTypes:success',
  FETCH_ALL_INVERTER_TYPES__FAIL = 'property|fetchAllInverterTypes:fail',
  FETCH_ALL_HOMEOWNER_PROPERTIES__START = 'property|fetchAllHomeownerProperties:start',
  FETCH_ALL_HOMEOWNER_PROPERTIES__SUCCESS = 'property|fetchAllHomeownerProperties:success',
  FETCH_ALL_HOMEOWNER_PROPERTIES__FAIL = 'property|fetchAllHomeownerProperties:fail',
  FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__START = 'property|fetchPropertyDashboardProductionData:start',
  FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__SUCCESS = 'property|fetchPropertyDashboardProductionData:success',
  FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__FAIL = 'property|fetchPropertyDashboardProductionData:fail',
  UPDATE_APPLIANCES__START = 'property|updateAppliances:start',
  UPDATE_APPLIANCES__SUCCESS = 'property|updateAppliances:success',
  UPDATE_APPLIANCES__FAIL = 'property|updateAppliances:fail',
  CHANGE_ANALYZE_GRAPH_SETTINGS__START = 'property|changeAnalyzeGraphSettings:start',
  CHANGE_ANALYZE_GRAPH_SETTINGS__IN_PROGRESS = 'property|changeAnalyzeGraphSettings:inProgress',
  CHANGE_ANALYZE_GRAPH_SETTINGS__SUCCESS = 'property|changeAnalyzeGraphSettings:success',
  CHANGE_ANALYZE_GRAPH_SETTINGS__FAIL = 'property|changeAnalyzeGraphSettings:fail',
}

export enum AnalyzeMode {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export enum AnalyzeAction {
  Previous = 'Previous',
  Current = 'Current',
  Refresh = 'Refresh',
  Next = 'Next',
}

export type EnergyProduction = SystemEnergyVm & { zeroed?: true };

export type PropertyState = {
  registration: {
    property: PropertyDetailsVm | null;
    addressStatus: PropertyAddressCheckStatus | null;
    inverterSerialNumberAlreadyInUse: boolean | null;
    inverterManufacturers: string[];
    invertersByManufacturer: { [manufacturer: string]: InverterTypeVm[] };
  };
  all: PropertyVm[];
  selected: PropertyVm | null;
  dashboard: Nullable<{
    lastUpdatedAt: string;
    last7Days: number;
    today: number;
    thisMonth: number;
    thisYear: number;
    lifetime: number;
  }>;
  analyze: Nullable<{
    mode: AnalyzeMode;
    startTimestamp: string;
    endTimestamp: string;
    firstRecordDate: string;
    energyProduction: EnergyProduction[];
  }>;
};

export interface NewPropertyBeginRegistrationActionStart {
  type: PropertyActionTypes.NEW_PROPERTY_BEGIN_REGISTRATION_START;
}

export interface NewPropertyBeginRegistrationActionSuccess {
  type: PropertyActionTypes.NEW_PROPERTY_BEGIN_REGISTRATION_SUCCESS;
  payload: PropertyDetailsVm;
}

export interface NewPropertyBeginRegistrationActionFail {
  type: PropertyActionTypes.NEW_PROPERTY_BEGIN_REGISTRATION_FAIL;
  error: AxiosError<WrappedPropertyDetailsVm>;
}

export interface NewPropertyCheckAddressActionStart {
  type: PropertyActionTypes.NEW_PROPERTY_CHECK_ADDRESS_START;
  apiRequest: typeof PropertyController.newPropertyCheckAddress;
  // TODO: If BE will change the request to POST and create a TS model - replace it here
  payload: [Omit<AddPropertyDetailsRequestVm, 'systemSize'>];
  followupActions: {
    SUCCESS: (
      payload: NewPropertyCheckAddressActionSuccess['payload'],
    ) => NewPropertyCheckAddressActionSuccess;
    FAIL: (error: NewPropertyCheckAddressActionFail['error']) => NewPropertyCheckAddressActionFail;
  };
}

export interface NewPropertyCheckAddressActionSuccess extends GenericApiActionSuccess {
  type: PropertyActionTypes.NEW_PROPERTY_CHECK_ADDRESS_SUCCESS;
  payload: AxiosResponse<WrappedCheckPropertyAddressResponseVm>;
}

export interface NewPropertyCheckAddressActionFail extends GenericApiActionFail {
  type: PropertyActionTypes.NEW_PROPERTY_CHECK_ADDRESS_FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface NewPropertySaveDetailsActionStart {
  type: PropertyActionTypes.NEW_PROPERTY_SAVE_DETAILS_START;
  payload: AddPropertyDetailsRequestVm;
}

export interface NewPropertySaveDetailsActionSuccess {
  type: PropertyActionTypes.NEW_PROPERTY_SAVE_DETAILS_SUCCESS;
  payload: AddPropertyDetailsRequestVm;
}

export interface NewPropertySaveDetailsActionFail {
  type: PropertyActionTypes.NEW_PROPERTY_SAVE_DETAILS_FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface NewPropertyAddInvertersActionStart {
  type: PropertyActionTypes.NEW_PROPERTY_ADD_INVERTERS_START;
  payload: NewPropertyInverter[];
}

export interface NewPropertyAddInvertersActionSuccess {
  type: PropertyActionTypes.NEW_PROPERTY_ADD_INVERTERS_SUCCESS;
  payload: PropertyDetailsVm;
}

export interface NewPropertyAddInvertersActionFail {
  type: PropertyActionTypes.NEW_PROPERTY_ADD_INVERTERS_FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface NewPropertyResetRegistrationActionStart {
  type: PropertyActionTypes.NEW_PROPERTY_RESET_REGISTRATION_START;
  apiRequest: typeof PropertyController.newPropertyResetRegistration;
  payload: [ResetPropertyRegistrationRequestVm];
  followupActions: {
    SUCCESS: (
      payload: NewPropertyResetRegistrationActionSuccess['payload'],
    ) => NewPropertyResetRegistrationActionSuccess;
    FAIL: (
      error: NewPropertyResetRegistrationActionFail['error'],
    ) => NewPropertyResetRegistrationActionFail;
  };
}

export interface NewPropertyResetRegistrationActionSuccess extends GenericApiActionSuccess {
  type: PropertyActionTypes.NEW_PROPERTY_RESET_REGISTRATION_SUCCESS;
  payload: AxiosResponse<WrappedResponseVm>;
}

export interface NewPropertyResetRegistrationActionFail extends GenericApiActionFail {
  type: PropertyActionTypes.NEW_PROPERTY_RESET_REGISTRATION_FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface NewPropertyFinishRegistrationActionStart {
  type: PropertyActionTypes.NEW_PROPERTY_FINISH_REGISTRATION_START;
  payload: FinishPropertyRegistrationRequestVm;
}

export interface NewPropertyFinishRegistrationActionSuccess {
  type: PropertyActionTypes.NEW_PROPERTY_FINISH_REGISTRATION_SUCCESS;
  payload: AxiosResponse<WrappedFinishPropertyRegistrationResponseVm>;
}

export interface NewPropertyFinishRegistrationActionFail {
  type: PropertyActionTypes.NEW_PROPERTY_FINISH_REGISTRATION_FAIL;
  error: AxiosError<WrappedFinishPropertyRegistrationResponseVm>;
}

export interface CheckInverterSerialNumberActionStart {
  type: PropertyActionTypes.CHECK_INVERTER_SERIAL_NUMBER_START;
  apiRequest: typeof PropertyController.checkInverterSerialNumber;
  payload: [serialNumber: string];
  followupActions: {
    SUCCESS: (
      payload: CheckInverterSerialNumberActionSuccess['payload'],
    ) => CheckInverterSerialNumberActionSuccess;
    FAIL: (
      error: CheckInverterSerialNumberActionFail['error'],
    ) => CheckInverterSerialNumberActionFail;
  };
}

export interface CheckInverterSerialNumberActionSuccess extends GenericApiActionSuccess {
  type: PropertyActionTypes.CHECK_INVERTER_SERIAL_NUMBER_SUCCESS;
  payload: AxiosResponse<WrappedCheckInverterSerialNumberResponseVm>;
}

export interface CheckInverterSerialNumberActionFail extends GenericApiActionFail {
  type: PropertyActionTypes.CHECK_INVERTER_SERIAL_NUMBER_FAIL;
  error: AxiosError<WrappedCheckInverterSerialNumberResponseVm>;
}

export interface FetchAllInverterTypesActionStart {
  type: PropertyActionTypes.FETCH_ALL_INVERTER_TYPES__START;
  apiRequest: typeof PropertyController.fetchAllInverterTypes;
  followupActions: {
    SUCCESS: (
      payload: FetchAllInverterTypesActionSuccess['payload'],
    ) => FetchAllInverterTypesActionSuccess;
    FAIL: (error: FetchAllInverterTypesActionFail['error']) => FetchAllInverterTypesActionFail;
  };
}

export interface FetchAllInverterTypesActionSuccess extends GenericApiActionSuccess {
  type: PropertyActionTypes.FETCH_ALL_INVERTER_TYPES__SUCCESS;
  payload: AxiosResponse<WrappedGetInverterTypesResponseVm>;
}

export interface FetchAllInverterTypesActionFail extends GenericApiActionFail {
  type: PropertyActionTypes.FETCH_ALL_INVERTER_TYPES__FAIL;
  error: AxiosError<WrappedGetInverterTypesResponseVm>;
}

export interface FetchAllHomeownerPropertiesActionStart {
  type: PropertyActionTypes.FETCH_ALL_HOMEOWNER_PROPERTIES__START;
  apiRequest: typeof PropertyController.fetchAllHomeownerProperties;
  followupActions: {
    SUCCESS: (
      payload: FetchAllHomeownerPropertiesActionSuccess['payload'],
    ) => FetchAllHomeownerPropertiesActionSuccess;
    FAIL: (
      error: FetchAllHomeownerPropertiesActionFail['error'],
    ) => FetchAllHomeownerPropertiesActionFail;
  };
}

export interface FetchAllHomeownerPropertiesActionSuccess extends GenericApiActionSuccess {
  type: PropertyActionTypes.FETCH_ALL_HOMEOWNER_PROPERTIES__SUCCESS;
  payload: AxiosResponse<WrappedGetHomeOwnerPropertiesResponseVm>;
}

export interface FetchAllHomeownerPropertiesActionFail extends GenericApiActionFail {
  type: PropertyActionTypes.FETCH_ALL_HOMEOWNER_PROPERTIES__FAIL;
  error: AxiosError<WrappedGetHomeOwnerPropertiesResponseVm>;
}

export interface FetchPropertyDashboardProductionDataActionStart {
  type: PropertyActionTypes.FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__START;
}

export interface FetchPropertyDashboardProductionDataActionSuccess {
  type: PropertyActionTypes.FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__SUCCESS;
  payload: PropertyState['dashboard'] & Pick<PropertyState['analyze'], 'firstRecordDate'>;
}

export interface FetchPropertyDashboardProductionDataActionFail {
  type: PropertyActionTypes.FETCH_PROPERTY_DASHBOARD_PRODUCTION_DATA__FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface UpdateAppliancesActionStart {
  type: PropertyActionTypes.UPDATE_APPLIANCES__START;
  payload: [UpdatePropertyAppliancesRequestVM];
  apiRequest: typeof PropertyController.updateAppliances;
  followupActions: {
    SUCCESS: (payload: UpdateAppliancesActionSuccess['payload']) => UpdateAppliancesActionSuccess;
    FAIL: (error: UpdateAppliancesActionFail['error']) => UpdateAppliancesActionFail;
  };
}

export interface UpdateAppliancesActionSuccess extends GenericApiActionSuccess {
  type: PropertyActionTypes.UPDATE_APPLIANCES__SUCCESS;
  payload: AxiosResponse<WrappedResponseVm>;
}

export interface UpdateAppliancesActionFail extends GenericApiActionFail {
  type: PropertyActionTypes.UPDATE_APPLIANCES__FAIL;
  error: AxiosError<WrappedResponseVm>;
}

export interface ChangeAnalyzeGraphSettingsActionStart {
  type: PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__START;
  payload: { mode: AnalyzeMode; action: AnalyzeAction };
}

export interface ChangeAnalyzeGraphSettingsInProgress {
  type: PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__IN_PROGRESS;
  payload: Omit<Required<PropertyState['analyze']>, 'energyProduction'>;
}

export interface ChangeAnalyzeGraphSettingsActionSuccess {
  type: PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__SUCCESS;
  payload: { energyProduction: SystemEnergyVm[] };
}

export interface ChangeAnalyzeGraphSettingsActionFail {
  type: PropertyActionTypes.CHANGE_ANALYZE_GRAPH_SETTINGS__FAIL;
  error: AxiosError<EnergyProduction[]>;
}

export type PropertyActions =
  | NewPropertyBeginRegistrationActionStart
  | NewPropertyBeginRegistrationActionSuccess
  | NewPropertyBeginRegistrationActionFail
  | NewPropertyCheckAddressActionStart
  | NewPropertyCheckAddressActionSuccess
  | NewPropertyCheckAddressActionFail
  | NewPropertySaveDetailsActionStart
  | NewPropertySaveDetailsActionSuccess
  | NewPropertySaveDetailsActionFail
  | NewPropertyAddInvertersActionStart
  | NewPropertyAddInvertersActionSuccess
  | NewPropertyAddInvertersActionFail
  | NewPropertyResetRegistrationActionStart
  | NewPropertyResetRegistrationActionSuccess
  | NewPropertyResetRegistrationActionFail
  | NewPropertyFinishRegistrationActionStart
  | NewPropertyFinishRegistrationActionSuccess
  | NewPropertyFinishRegistrationActionFail
  | CheckInverterSerialNumberActionStart
  | CheckInverterSerialNumberActionSuccess
  | CheckInverterSerialNumberActionFail
  | FetchAllInverterTypesActionStart
  | FetchAllInverterTypesActionSuccess
  | FetchAllInverterTypesActionFail
  | FetchAllHomeownerPropertiesActionStart
  | FetchAllHomeownerPropertiesActionSuccess
  | FetchAllHomeownerPropertiesActionFail
  | FetchPropertyDashboardProductionDataActionStart
  | FetchPropertyDashboardProductionDataActionSuccess
  | FetchPropertyDashboardProductionDataActionFail
  | UpdateAppliancesActionStart
  | UpdateAppliancesActionSuccess
  | UpdateAppliancesActionFail
  | ChangeAnalyzeGraphSettingsActionStart
  | ChangeAnalyzeGraphSettingsInProgress
  | ChangeAnalyzeGraphSettingsActionSuccess
  | ChangeAnalyzeGraphSettingsActionFail;
