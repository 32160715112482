import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore, Middleware, Store, StoreEnhancer } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { EnvironmentService } from 'services/Environment';

import { rootReducer } from './reducers';
import { rootSaga } from './sagas';
import { AllActions, RootState } from './types';

export const configureStore = (preloadedState: any = null): Store<RootState, AllActions> => {
  // Create saga middleware
  const sagaMiddleware = createSagaMiddleware();
  // Create redux logger
  const logger = createLogger({ collapsed: true });

  // Gather all middlewares
  const allMiddlewares: Middleware[] = [sagaMiddleware];

  // Add logger on DEV environment
  if (EnvironmentService.isDEV()) allMiddlewares.push(logger);

  // Compose all middlewares
  const rootMiddleware: StoreEnhancer =
    EnvironmentService.isDEV() || EnvironmentService.isQA()
      ? composeWithDevTools(applyMiddleware(...allMiddlewares))
      : applyMiddleware(...allMiddlewares);

  // Create Redux store
  let store;

  if (preloadedState) {
    store = createStore(rootReducer, preloadedState, rootMiddleware);
  } else {
    store = createStore(rootReducer, rootMiddleware);
  }

  // Apply all Sagas
  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = configureStore();
export * from './types';
