export const ROUTES = {
  Auth: {
    Home: '/',
    Login: '/login',
    LoginAsAdmin: '/loginAsAdmin',
    Register: {
      _: '/register',
      CheckEmailAddress: '/register/check-email',
      CreateAccount: '/register/create-account',
      CheckYourInbox: '/register/check-your-inbox',
    },
  },
  Public: {
    ContactSupport: '/contact-support',
    ForgotPassword: '/forgot-password',
    PageSessionExpired: '/session-expired',
    HealthCheck: '/health-check',
  },
  Private: {
    Account: '/account',
    Analyze: '/analyze',
    Dashboard: '/dashboard',
    PropertyRegistration: '/register-property',
    Referral: '/referral',
    Support: {
      _: '/support',
      KnowledgeBase: '/support/knowledge-base',
      ContactUs: '/support/contact-us',
    },
  },
  System: {
    EmailVerification: '/verifyemail',
    PasswordReset: '/resetpassword',
  },
};
