import { AxiosResponse } from 'axios';
import { all, call, delay, put } from 'redux-saga/effects';

import { Ampli } from 'services/Ampli';
import { BrowserStorageService } from 'services/BrowserStorage';
import { EnvironmentService } from 'services/Environment';
import { NavigationService } from 'services/Navigation';
import { Sentry } from 'services/Sentry';
import * as userActions from 'store/reducers/user/actions';
import {
  CheckNewUserEmailActionStart,
  LogInUserActionStart,
  RegisterExistingHomeownerUserActionStart,
  RegisterNewHomeownerUserActionStart,
  VerifyEmailAddressActionStart,
} from 'store/reducers/user/types';
import { handleErrorCaughtBySaga } from 'store/sagas/helpers/handleErrorCaughtBySaga';
import {
  EmailAddressStatus,
  EmailVerificationStatus,
  ExistingHomeOwnerRegistrationFinishStatus,
  LogInStatus,
  WrappedCheckEmailAddressResponseVm,
  WrappedFinishRegistrationOfExistingHomeOwnerResponseVm,
  WrappedLogInHomeOwnerResponseVm,
  WrappedResponseVm,
  WrappedVerifyEmailResponseVm,
} from 'types';
import { MINIMAL_API_PROMISE_RESOLVE_TIME } from 'variables';

import { UserController } from './UserController';

export function* checkNewUserEmailAddressSaga(action: CheckNewUserEmailActionStart) {
  try {
    // Send request to Backend to check provided email address
    type Response = AxiosResponse<WrappedCheckEmailAddressResponseVm>;
    const [{ data: response }]: [Response] = yield all([
      call(UserController.checkNewUserEmailAddress, ...action.payload),
      delay(MINIMAL_API_PROMISE_RESOLVE_TIME),
    ]);

    switch (response.data.emailStatus) {
      // Redirect user to a "Contact Support" page
      case EmailAddressStatus.MultipleHomeOwnersExist:
      case EmailAddressStatus.DeactivatedHomeOwnerExists: {
        yield call(
          [NavigationService, NavigationService.goTo],
          NavigationService.ROUTES.Public.ContactSupport,
        );
        break;
      }

      // This is a completely new email address
      // Proceed with registration process
      case EmailAddressStatus.VacantEmailAddress: {
        yield call(
          [NavigationService, NavigationService.goTo],
          NavigationService.ROUTES.Auth.Register.CreateAccount,
          { state: { emailAddress: action.payload[0].emailAddress } },
        );
        break;
      }

      // User exists in SalesForce but didn't verify their email address
      // Backend will send email verification link before responding to Frontend
      case EmailAddressStatus.NotActivatedHomeOwnerExists:
      case EmailAddressStatus.RegistrationInProgress: {
        yield call(
          [NavigationService, NavigationService.goTo],
          NavigationService.ROUTES.Auth.Register.CheckYourInbox,
          { state: { emailAddress: action.payload[0].emailAddress } },
        );
        break;
      }

      // Email address was already taken by someone else
      // Stay on the same page which will handle the response by itself
      case EmailAddressStatus.ActivatedHomeOwnerExists:
        break;

      /* istanbul ignore next */
      default: {
        // This code should never be executed.
        // Execution of this code means there is something
        // not in sync between Frontend and Backend.
        if (EnvironmentService.isDEV()) {
          console.error(
            'UserControllerSaga - checkNewUserEmailAddressSaga\n',
            'Unknown EmailAddressStatus\n',
            `${response.data.emailStatus}`,
          );
        }
      }
    }

    // Mark request as completed
    yield put(userActions.checkNewUserEmailAddressSuccess(response));
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      userActions.checkNewUserEmailAddressFail,
      'UserControllerSaga - checkNewUserEmailAddressSaga',
    );
  }
}

export function* logInUserSaga(action: LogInUserActionStart) {
  try {
    yield call(BrowserStorageService.removeAll, true);
    // Send request to Backend to log user in
    type Response = AxiosResponse<WrappedLogInHomeOwnerResponseVm>;
    const [{ headers, data: response }]: [Response] = yield all([
      call(UserController.logIn, ...action.payload),
      delay(MINIMAL_API_PROMISE_RESOLVE_TIME),
    ]);

    switch (response.data.logInStatus) {
      // User logged in successfully
      case LogInStatus.Success: {
        // Save csrf token to browser storage
        yield call(BrowserStorageService.csrfToken.set, headers['x-csrf-token']);
        yield put(userActions.markUserAsLoggedIn());
        // Navigate user to application dashboard page
        yield call(
          [NavigationService, NavigationService.goTo],
          NavigationService.ROUTES.Private.Dashboard,
        );
        break;
      }

      // Email and/or password didn't pass Backend validation
      case LogInStatus.EmailOrPasswordIsWrong:
        break;

      /* istanbul ignore next */
      default: {
        // This code should never be executed.
        // Execution of this code means there is something
        // not in sync between Frontend and Backend.
        if (EnvironmentService.isDEV()) {
          console.error(
            'UserControllerSaga - logInUserSaga\n',
            'Unknown LogInStatus\n',
            `${response.data.logInStatus}`,
          );
        }
      }
    }

    // Mark request as completed
    yield put(userActions.logInUserSuccess(response));
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      userActions.logInUserFail,
      'UserControllerSaga - logInUserSaga',
    );
  }
}

export function* loginAdminUserAsHomeownerSaga(action: LogInUserActionStart) {
  try {
    // Send request to Backend to log user in
    type Response = AxiosResponse<WrappedLogInHomeOwnerResponseVm>;
    const [{ headers, data: response }]: [Response] = yield all([
      call(UserController.logInAdminAsHomeowner, action.payload),
      delay(MINIMAL_API_PROMISE_RESOLVE_TIME),
    ]);

    switch (response.data.logInStatus) {
      // User logged in successfully
      case LogInStatus.Success: {
        // Save csrf token to browser storage
        yield call(BrowserStorageService.csrfToken.set, headers['x-csrf-token'], true);
        yield put(userActions.markUserAsLoggedIn());
        // Navigate user to application dashboard page
        yield call(
          [NavigationService, NavigationService.goTo],
          NavigationService.ROUTES.Private.Dashboard,
        );
        break;
      }
      // Secret didn't pass Backend validation
      case LogInStatus.InvalidOrExpiredAdminLogInSession:
        // Navigate user to the Home page
        yield call([NavigationService, NavigationService.goTo], NavigationService.ROUTES.Auth.Home);
        break;
      /* istanbul ignore next */
      default: {
        // This code should never be executed.
        // Execution of this code means there is something
        // not in sync between Frontend and Backend.
        if (EnvironmentService.withLogs) {
          console.error(
            'UserControllerSaga - logInUserSaga\n',
            'Unknown LogInStatus\n',
            `${response.data.logInStatus}`,
          );
        }
      }
    }

    // Mark request as completed
    yield put(userActions.logInAdminAsHomeownerSuccess(response));
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      userActions.logInAdminAsHomeownerFail,
      'UserControllerSaga - logInUserSaga',
    );
  }
}

export function* logOutUserSaga() {
  try {
    // Send request to Backend to log user out
    yield call(UserController.logOut);
  } catch {
    // Do nothing
  }

  // Clear browser storage
  localStorage.clear();
  sessionStorage.clear();

  Sentry.clearUser();
  Ampli.clearUser();

  // Navigate user to the Home page
  yield call([NavigationService, NavigationService.goTo], NavigationService.ROUTES.Auth.Home);
  // Mark the request as completed
  yield put(userActions.logOutUserSuccess());
}

export function* registerNewHomeownerUserSaga(action: RegisterNewHomeownerUserActionStart) {
  try {
    // Send request to Backend to register user
    type Response = AxiosResponse<WrappedResponseVm>;
    const [{ headers }]: [Response] = yield all([
      call(UserController.registerNewHomeowner, ...action.payload),
      delay(MINIMAL_API_PROMISE_RESOLVE_TIME),
    ]);

    // Save csrf token to browser storage
    yield call(BrowserStorageService.csrfToken.set, headers['x-csrf-token']);
    yield put(userActions.markUserAsLoggedIn());
    // Navigate user to application dashboard page
    yield call(
      [NavigationService, NavigationService.goTo],
      NavigationService.ROUTES.Private.Dashboard,
      { state: { isRedirectedFromAccountRegistration: true } },
    );

    // Mark request as completed
    yield put(userActions.registerNewHomeownerUserSuccess());
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      userActions.registerNewHomeownerUserFail,
      'UserControllerSaga - registerNewHomeownerUserSaga',
    );
  }
}

export function* registerExistingHomeownerUserSaga(
  action: RegisterExistingHomeownerUserActionStart,
) {
  try {
    // Send request to Backend to register user
    type Response = AxiosResponse<WrappedFinishRegistrationOfExistingHomeOwnerResponseVm>;
    const [{ headers, data: response }]: [Response] = yield all([
      call(UserController.registerExistingHomeowner, ...action.payload),
      delay(MINIMAL_API_PROMISE_RESOLVE_TIME),
    ]);

    switch (response.data.registrationStatus) {
      // User successfully created their account
      case ExistingHomeOwnerRegistrationFinishStatus.Success: {
        // Save csrf token to browser storage
        yield call(BrowserStorageService.csrfToken.set, headers['x-csrf-token']);
        yield put(userActions.markUserAsLoggedIn());
        // Navigate user to application dashboard page
        yield call(
          [NavigationService, NavigationService.goTo],
          NavigationService.ROUTES.Private.Dashboard,
          { state: { isRedirectedFromAccountRegistration: true } },
        );
        break;
      }

      // Verification link invalid or expired = don't redirect anywhere.
      // The page will handle the response by itself.
      case ExistingHomeOwnerRegistrationFinishStatus.SecretIsExpired:
      case ExistingHomeOwnerRegistrationFinishStatus.EmailOrSecretIsInvalid:
        break;

      // Users account is deactivated = redirect to Contact Support page
      case ExistingHomeOwnerRegistrationFinishStatus.HomeOwnerIsInactive: {
        yield call(
          [NavigationService, NavigationService.goTo],
          NavigationService.ROUTES.Public.ContactSupport,
        );
        break;
      }

      // User has already verified their email address = redirect to Home page
      case ExistingHomeOwnerRegistrationFinishStatus.HomeOwnerIsAlreadyRegistered: {
        yield call([NavigationService, NavigationService.goTo], NavigationService.ROUTES.Auth.Home);
        break;
      }

      /* istanbul ignore next */
      default: {
        // This code should never be executed.
        // Execution of this code means there is something
        // not in sync between Frontend and Backend.
        if (EnvironmentService.isDEV()) {
          console.error(
            'UserControllerSaga - registerExistingHomeownerUserSaga\n',
            'Unknown ExistingHomeOwnerRegistrationFinishStatus\n',
            `${response.data.registrationStatus}`,
          );
        }
      }
    }

    // Mark request as completed
    yield put(userActions.registerExistingHomeownerUserSuccess(response));
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      userActions.registerExistingHomeownerUserFail,
      'UserControllerSaga - registerExistingHomeownerUserSaga',
    );
  }
}

export function* verifyEmailAddressSaga(action: VerifyEmailAddressActionStart) {
  try {
    // Send request to Backend to register user
    type Response = AxiosResponse<WrappedVerifyEmailResponseVm>;
    const [{ data: response }]: [Response] = yield all([
      call(UserController.verifyEmailAddress, ...action.payload),
      delay(MINIMAL_API_PROMISE_RESOLVE_TIME),
    ]);

    switch (response.data.verificationStatus) {
      // Verification link invalid or expired = don't redirect anywhere.
      // The page will handle the response by itself.
      case EmailVerificationStatus.EmailOrSecretIsInvalid:
      case EmailVerificationStatus.SecretIsExpired:
        break;

      // Users account is deactivated = redirect to Contact Support page
      case EmailVerificationStatus.HomeOwnerIsInactive: {
        yield call(
          [NavigationService, NavigationService.goTo],
          NavigationService.ROUTES.Public.ContactSupport,
        );
        break;
      }

      // User has to finish their account registration process = redirect to Account Creation page
      case EmailVerificationStatus.ExistingHomeOwnerShouldFinishRegistration: {
        yield call(
          [NavigationService, NavigationService.goTo],
          NavigationService.ROUTES.Auth.Register.CreateAccount,
          {
            state: {
              existingHomeowner: response.data.existingHomeOwner,
              secret: action.payload[0].secret,
            },
          },
        );
        break;
      }

      // User successfully verified their email address = redirect to Home page
      case EmailVerificationStatus.NewHomeOwnerIsVerified:
      case EmailVerificationStatus.HomeOwnerIsAlreadyVerified: {
        yield call([NavigationService, NavigationService.goTo], NavigationService.ROUTES.Auth.Home);
        break;
      }

      /* istanbul ignore next */
      default: {
        // This code should never be executed.
        // Execution of this code means there is something
        // not in sync between Frontend and Backend.
        if (EnvironmentService.isDEV()) {
          console.error(
            'UserControllerSaga - verifyEmailAddressSaga\n',
            'Unknown EmailVerificationStatus\n',
            `${response.data.verificationStatus}`,
          );
        }
      }
    }

    // Mark request as completed
    yield put(userActions.verifyEmailAddressSuccess(response));
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      userActions.verifyEmailAddressFail,
      'UserControllerSaga - verifyEmailAddressSaga',
    );
  }
}
