import { produce } from 'immer';

import { AllActions } from 'store';

import { SupportActionTypes, SupportState as State } from './types';

export const initialState: State = {
  knowledgeBase: {
    categories: [],
    articles: {},
  },
};

export const supportReducer = (state: State = initialState, action: AllActions): State =>
  produce(state, (newState: State) => {
    switch (action.type) {
      case SupportActionTypes.FETCH_KNOWLEDGE_BASE_DATA__SUCCESS: {
        const { categories } = action.payload.data.data;

        newState.knowledgeBase.categories = categories.map(category => category.categoryName);
        newState.knowledgeBase.articles = categories.reduce((articles, category) => {
          return { ...articles, [category.categoryName]: category.articles };
        }, {} as State['knowledgeBase']['articles']);

        break;
      }
    }
  });
