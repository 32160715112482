import React from 'react';
import { ProgressSpinner } from '@gafe/common-ui';

import { Page, Props } from './Page';

export const PageSpinner = ({ centered = true, ...props }: Props) => (
  <Page {...props} centered={centered}>
    <ProgressSpinner size="extra-large" />
  </Page>
);
