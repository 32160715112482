import React from 'react';

import { IconBrokenCable } from 'components/icons/IconBrokenCable';
import { Page } from 'components/layout/Page';

import styles from './UnexpectedErrorPage.module.scss';

export const UnexpectedErrorPage = () => (
  <Page withHeader centered className={styles.page} data-testid="unexpected-error">
    <IconBrokenCable classes={{ container: styles.icon }} />
    <div className={styles.title}>Something went wrong...</div>
    <div className={styles.message}>We’re currently investigating. Please try again shortly.</div>
  </Page>
);
