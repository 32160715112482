import { AxiosError, AxiosResponse } from 'axios';
import last from 'lodash/last';
import { DateTime } from 'luxon';
import { all, call, delay, put, select } from 'redux-saga/effects';

import { BrowserStorageService } from 'services/BrowserStorage';
import { EnergyProductionService } from 'services/EnergyProductionService';
import { NavigationService } from 'services/Navigation';
import * as propertyActions from 'store/reducers/property/actions';
import * as propertySelectors from 'store/reducers/property/selectors';
import {
  AnalyzeMode,
  ChangeAnalyzeGraphSettingsActionStart,
  EnergyProduction,
  NewPropertyAddInvertersActionStart,
  NewPropertyFinishRegistrationActionStart,
  NewPropertySaveDetailsActionStart,
  PropertyState,
} from 'store/reducers/property/types';
import { handleErrorCaughtBySaga } from 'store/sagas/helpers/handleErrorCaughtBySaga';
import {
  EnergyProductionInterval,
  EnergyProductionTimePeriod,
  PropertyDetailsVm,
  WrappedFinishPropertyRegistrationResponseVm,
  WrappedGetEnergyProductionResponseVm,
  WrappedGetHomeOwnerPropertiesResponseVm,
  WrappedPropertyDetailsVm,
  WrappedResponseVm,
} from 'types';
import { MINIMAL_API_PROMISE_RESOLVE_TIME } from 'variables';

import { PropertyController } from './PropertyController';
import { GetProductionDataOptions } from './types';

export function* newPropertyBeginRegistrationSaga() {
  try {
    // Fetch all homeowner's existing properties
    const allPropertiesResponse: AxiosResponse<WrappedGetHomeOwnerPropertiesResponseVm> =
      yield call(PropertyController.fetchAllHomeownerProperties);
    const allProperties = allPropertiesResponse.data.data.properties;

    // Check if registration process of any property of the homeowner can be resumed
    const resumableProperty = allProperties.find(p => p.canResumeRegistrationProcess);

    // Fetch resumable property details or create a brand-new property
    const propertyDetails: PropertyDetailsVm = yield (function* () {
      type Response = AxiosResponse<WrappedPropertyDetailsVm>;

      if (resumableProperty) {
        const response: Response = yield call(
          PropertyController.fetchPropertyDetails,
          resumableProperty.id,
        );

        return response.data.data;
      }

      const response: Response = yield call(PropertyController.newPropertyBeginRegistration);

      return response.data.data;
    })();

    // Store property details as an active property for registration
    yield put(propertyActions.newPropertyBeginRegistrationSuccess(propertyDetails));
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      propertyActions.newPropertyBeginRegistrationFail,
      'PropertyControllerSaga - newPropertyBeginRegistrationSaga',
    );
  }
}

export function* newPropertySaveDetailsSaga(action: NewPropertySaveDetailsActionStart) {
  try {
    // Save property details in SF
    yield all([
      call(PropertyController.newPropertySaveDetails, action.payload),
      delay(MINIMAL_API_PROMISE_RESOLVE_TIME),
    ]);

    // Save property details in Redux
    yield put(propertyActions.newPropertySaveDetailsSuccess(action.payload));
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      propertyActions.newPropertySaveDetailsFail,
      'PropertyControllerSaga - newPropertySaveDetailsSaga',
    );
  }
}

export function* newPropertyAddInvertersSaga(action: NewPropertyAddInvertersActionStart) {
  try {
    // Save inverters to the property
    yield all(action.payload.map(inverter => PropertyController.newPropertyAddInverters(inverter)));

    const propertyId = action.payload[0].propertyId;

    type Response = AxiosResponse<WrappedPropertyDetailsVm>;
    const response: Response = yield call(PropertyController.fetchPropertyDetails, propertyId);

    // Mark step as successful and store updated property details
    yield put(propertyActions.newPropertyAddInverterSuccess(response.data.data));
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      propertyActions.newPropertyAddInverterFail,
      'PropertyControllerSaga - newPropertyAddInvertersSaga',
    );
  }
}

export function* newPropertyFinishRegistration(action: NewPropertyFinishRegistrationActionStart) {
  try {
    const response: AxiosResponse<WrappedFinishPropertyRegistrationResponseVm> = yield call(
      PropertyController.newPropertyFinishRegistration,
      action.payload,
    );

    yield put(propertyActions.newPropertyFinishRegistrationSuccess(response));

    yield call(
      [NavigationService, NavigationService.goTo],
      NavigationService.ROUTES.Private.Dashboard,
      { state: { isRedirectedFromPropertyRegistration: true } },
    );
  } catch (error) {
    yield call(
      handleErrorCaughtBySaga,
      error,
      propertyActions.newPropertyFinishRegistrationFail,
      'PropertyControllerSaga - newPropertyFinishRegistration',
    );
  }
}

export function* fetchPropertyDashboardProductionData() {
  // Check if there is a property that is ready for energy production data monitoring
  type Property = ReturnType<typeof propertySelectors.selectCurrentlySelectedProperty>;
  const property: Property = yield select(propertySelectors.selectCurrentlySelectedProperty);

  if (!property || !property.timeZone) {
    yield put(
      propertyActions.fetchPropertyDashboardProductionDataFail({} as AxiosError<WrappedResponseVm>),
    );

    return;
  }

  const { timeZone } = property;
  const {
    getDateRange,
    getLast7DaysDateRange,
    getEnergyValue: { total },
  } = EnergyProductionService;

  // Fetch energy production data for different date ranges.
  // a.k.a. Promise.allSettled but in redux-saga
  const responses: PromiseSettledResult<AxiosResponse<WrappedGetEnergyProductionResponseVm>>[] =
    yield all(
      [
        // Today hourly
        {
          ...getDateRange({ timeZone, mode: AnalyzeMode.Day }).dateRange,
          timezone: timeZone,
        },
        // Last 7 days hourly
        {
          ...getLast7DaysDateRange(timeZone).dateRange,
          timezone: timeZone,
        },
        // This month daily
        {
          ...getDateRange({ timeZone, mode: AnalyzeMode.Month }).dateRange,
          interval: EnergyProductionInterval.Weekly,
          timezone: timeZone,
        },
        // Lifetime yearly
        {
          timePeriod: EnergyProductionTimePeriod.Lifetime,
          interval: EnergyProductionInterval.Yearly,
          timezone: timeZone,
        },
      ].map((args: GetProductionDataOptions) =>
        // TODO: figure out how to replace unknown with real types
        (function* (): unknown {
          try {
            return {
              status: 'fulfilled',
              value: yield call(PropertyController.fetchProductionData, property.id, args),
            };
          } catch (error) {
            return { status: 'rejected', reason: error };
          }
        })(),
      ),
    );

  // Check how many requests failed. Different UI should be displayed.
  const amountOfFailedRequests = responses.reduce((amount, response) => {
    return response.status === 'fulfilled' ? amount : amount + 1;
  }, 0);

  if (amountOfFailedRequests > 1) {
    yield put(
      propertyActions.fetchPropertyDashboardProductionDataFail({} as AxiosError<WrappedResponseVm>),
    );

    return;
  }

  // Grab different responses to process them separately
  const [todayResponse, last7DaysResponse, thisMonthResponse, lifetimeResponse] = responses;

  let last7Days: number | null = null;

  if (last7DaysResponse.status === 'fulfilled') {
    last7Days = total(last7DaysResponse.value.data.data.systemEnergy, 0);
  }

  let today: number | null = null;

  if (todayResponse.status === 'fulfilled') {
    today = total(todayResponse.value.data.data.systemEnergy, 0);
  }

  let thisMonth: number | null = null;

  if (thisMonthResponse.status === 'fulfilled') {
    thisMonth = total(thisMonthResponse.value.data.data.systemEnergy, 0);
  }

  let firstRecordDate: string | null = null;
  let thisYear: number | null = null;
  let lifetime: number | null = null;

  if (lifetimeResponse.status === 'fulfilled') {
    thisYear = total([last(lifetimeResponse.value.data.data.systemEnergy)!], 0);
    lifetime = total(lifetimeResponse.value.data.data.systemEnergy, 0);
    firstRecordDate = lifetimeResponse.value.data.data.startTimestamp;
  }

  yield put(
    propertyActions.fetchPropertyDashboardProductionDataSuccess({
      lastUpdatedAt: DateTime.now().setZone(timeZone).startOf('hour').toFormat('MM/dd/yy h:mma'),
      firstRecordDate,
      last7Days,
      today,
      thisMonth,
      thisYear,
      lifetime,
    }),
  );
}

export function* changeAnalyzeGraphSettings(action: ChangeAnalyzeGraphSettingsActionStart) {
  const { getDateRange, fillMissingRecords } = EnergyProductionService;

  // Check if there is a property that is ready for energy production data monitoring
  type Property = ReturnType<typeof propertySelectors.selectCurrentlySelectedProperty>;
  const property: Property = yield select(propertySelectors.selectCurrentlySelectedProperty);

  if (!property || !property.timeZone) {
    yield put(propertyActions.changeAnalyzeGraphSettingsFail({} as AxiosError<EnergyProduction[]>));

    return;
  }

  try {
    type Settings = PropertyState['analyze'];
    const { ...settings }: Settings = yield select(propertySelectors.selectAnalyzeGraphSettings);

    if (!settings.firstRecordDate) {
      const lifetimeResponse: AxiosResponse<WrappedGetEnergyProductionResponseVm> = yield call(
        PropertyController.fetchProductionData,
        property.id,
        {
          timePeriod: EnergyProductionTimePeriod.Lifetime,
          interval: EnergyProductionInterval.Yearly,
          timezone: property.timeZone,
        },
      );

      settings.firstRecordDate = lifetimeResponse.data.data.startTimestamp;
    }

    // Get date range and interval to make a correct request to energy production API
    // eslint-disable-next-line prefer-const
    let { dateRange, energyProduction, processableBySDDC } = getDateRange({
      timeZone: property.timeZone,
      mode: action.payload.mode,
      action: action.payload.action,
      startTimestamp: settings.startTimestamp || undefined,
      endTimestamp: settings.endTimestamp || undefined,
      firstRecordDate: settings.firstRecordDate,
    });

    // Save necessary settings to Redux so other components can use them right away
    yield put(
      propertyActions.changeAnalyzeGraphSettingsInProgress({
        mode: action.payload.mode,
        startTimestamp: dateRange.startTimestamp,
        endTimestamp: dateRange.endTimestamp,
        firstRecordDate: settings.firstRecordDate,
      }),
    );

    // Save necessary settings to browser storage to use them if user refreshes the page
    BrowserStorageService.analyzeGraph.set({
      mode: action.payload.mode,
      startTimestamp: dateRange.startTimestamp,
      endTimestamp: dateRange.endTimestamp,
      firstRecordDate: null,
      energyProduction: null,
    });

    if (processableBySDDC) {
      // Fetch energy production data
      type Responses = AxiosResponse<WrappedGetEnergyProductionResponseVm>;
      const response: Responses = yield call(
        PropertyController.fetchProductionData,
        property.id,
        {
          ...dateRange,
          timezone: property.timeZone,
        },
        true,
      );

      // Fill missing records with "zeros"
      energyProduction = fillMissingRecords(response.data.data.systemEnergy, {
        timeZone: property.timeZone,
        mode: action.payload.mode,
        startTimestamp: dateRange.startTimestamp,
        endTimestamp: dateRange.endTimestamp,
      });
    } else {
      yield delay(MINIMAL_API_PROMISE_RESOLVE_TIME);
    }

    // Story energy production data to Redux
    yield put(propertyActions.changeAnalyzeGraphSettingsSuccess({ energyProduction }));
  } catch (error: any) {
    if (error.response && error.response.status === 422) error.response.data = [];

    yield call(
      handleErrorCaughtBySaga,
      error,
      propertyActions.changeAnalyzeGraphSettingsFail,
      'PropertyControllerSaga - changeAnalyzeGraphSettings',
    );
  }
}
