import { takeEvery } from '@redux-saga/core/effects';

import { UserActionTypes } from 'store/reducers/user/types';

import {
  checkNewUserEmailAddressSaga,
  loginAdminUserAsHomeownerSaga,
  logInUserSaga,
  logOutUserSaga,
  registerExistingHomeownerUserSaga,
  registerNewHomeownerUserSaga,
  verifyEmailAddressSaga,
} from './UserController.saga';

const {
  LOG_IN__START,
  LOG_OUT__START,
  LOG_IN_ADMIN_AS_HOMEOWNER__START,
  VERIFY_EMAIL_ADDRESS__START,
  REGISTER_NEW_HOMEOWNER__START,
  CHECK_NEW_USER_EMAIL_ADDRESS__START,
  REGISTER_EXISTING_HOMEOWNER__START,
} = UserActionTypes;

export { UserController } from './UserController';
export function* UserControllerSagas() {
  yield takeEvery(CHECK_NEW_USER_EMAIL_ADDRESS__START, checkNewUserEmailAddressSaga);
  yield takeEvery(LOG_IN__START, logInUserSaga);
  yield takeEvery(LOG_IN_ADMIN_AS_HOMEOWNER__START, loginAdminUserAsHomeownerSaga);
  yield takeEvery(REGISTER_NEW_HOMEOWNER__START, registerNewHomeownerUserSaga);
  yield takeEvery(REGISTER_EXISTING_HOMEOWNER__START, registerExistingHomeownerUserSaga);
  yield takeEvery(VERIFY_EMAIL_ADDRESS__START, verifyEmailAddressSaga);
  yield takeEvery(LOG_OUT__START, logOutUserSaga);
}
