import { AxiosPromise } from 'axios';
import { DateTime } from 'luxon';

import { Axios, AxiosService } from 'services/Axios';
import {
  AddPropertyDetailsRequestVm,
  FinishPropertyRegistrationRequestVm,
  ObjectKeys,
  ResetPropertyRegistrationRequestVm,
  UpdatePropertyAppliancesRequestVM,
  WrappedCheckInverterSerialNumberResponseVm,
  WrappedCheckPropertyAddressResponseVm,
  WrappedFinishPropertyRegistrationResponseVm,
  WrappedGetEnergyProductionResponseVm,
  WrappedGetHomeOwnerPropertiesResponseVm,
  WrappedGetInverterTypesResponseVm,
  WrappedPropertyDetailsVm,
  WrappedResponseVm,
} from 'types';

import { GetProductionDataOptions, NewPropertyInverter } from './types';

export class PropertyControllerClass {
  constructor(private APIService: AxiosService) {}

  /**
   * Start registration of a new property that will belong to current user (homeowner).
   * Protected by Cookie authorization.
   */
  newPropertyBeginRegistration = (): AxiosPromise<WrappedPropertyDetailsVm> => {
    return this.APIService.post('property/start-registration');
  };

  /**
   * Step 1 of property registration flow.
   * Check if specified address can be used to register a new property.
   * Should be called before displaying a confirmation screen for step 1 of property registration flow.
   * Protected by Cookie authorization.
   */
  newPropertyCheckAddress = (
    // TODO: maybe? move Omit statement to types/proxy.ts
    params: Omit<AddPropertyDetailsRequestVm, 'systemSize'>,
  ): AxiosPromise<WrappedCheckPropertyAddressResponseVm> => {
    return this.APIService.get('property/check-address', { params });
  };

  /**
   * Step 1 of property registration flow.
   * Continue the flow by adding property details which include address and system information.
   * Should be called after the homeowner confirms the captured information for step 1 of property registration flow.
   * Protected by Cookie authorization.
   */
  newPropertySaveDetails = (
    payload: AddPropertyDetailsRequestVm,
  ): AxiosPromise<WrappedResponseVm> => {
    return this.APIService.post('property/add-details', payload);
  };

  /**
   * Step 2 of property registration flow.
   * Continue the flow by adding inverter details which include serial number, manufacturer, photo, etc.
   * Should be called for each inverter registration in step 2 of property registration flow.
   * Protected by Cookie authorization.
   */
  newPropertyAddInverters = (payload: NewPropertyInverter): AxiosPromise<WrappedResponseVm> => {
    const multipartFormData = new FormData();

    (Object.keys(payload) as ObjectKeys<NewPropertyInverter>).forEach(key => {
      multipartFormData.append(key, payload[key]);
    });

    return this.APIService.post('property/add-inverter', multipartFormData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  };

  /**
   * Step 2 and 3 of property registration flow.
   * Reset property registration (start from scratch) by marking the related case as canceled and removing all captured address-related information.
   * Should be called from confirmation screens of step 2 and step 3 of property registration flows.
   * Protected by Cookie authorization.
   */
  newPropertyResetRegistration = (
    payload: ResetPropertyRegistrationRequestVm,
  ): AxiosPromise<WrappedResponseVm> => {
    return this.APIService.post('property/reset-registration', payload);
  };

  /**
   * Step 3 of property registration flow.
   * Finish property registration and further submitting it to SF => SDDC registration.
   * Should be called from step 3 of property registration flow.
   * Protected by Cookie authorization.
   */
  newPropertyFinishRegistration = (
    payload: FinishPropertyRegistrationRequestVm,
  ): AxiosPromise<WrappedFinishPropertyRegistrationResponseVm> => {
    return this.APIService.post('property/finish-registration', payload);
  };

  /**
   * Step 2 of property registration flow.
   * Get all inverter types currently supported by the Customer Portal.
   * Should be called after the homeowner arrives to step 2 of property registration flow.
   * Protected by Cookie authorization.
   */
  fetchAllInverterTypes = (): AxiosPromise<WrappedGetInverterTypesResponseVm> => {
    return this.APIService.get('property/get-inverter-types');
  };

  /**
   * Step 2 of property registration flow.
   * Check if specified inverter serial number is not taken yet and can be used in property registration flow.
   * Should be called when the homeowner inputs serial number of his/her inverter to check if it is vacant or not.
   * In case of inverter update, inverter id should be also passed.
   * Protected by Cookie authorization.
   */
  checkInverterSerialNumber = (
    inverterSerialNumber: string,
  ): AxiosPromise<WrappedCheckInverterSerialNumberResponseVm> => {
    return this.APIService.get('property/check-inverter-serial-number', {
      params: { inverterSerialNumber },
    });
  };

  /**
   * Get all properties of current user (homeowner).
   * Protected by Cookie authorization.
   */
  fetchAllHomeownerProperties = (): AxiosPromise<WrappedGetHomeOwnerPropertiesResponseVm> => {
    return this.APIService.get('property/get-all');
  };

  /**
   * Get details of specific property.
   * Protected by Cookie authorization.
   */
  fetchPropertyDetails = (propertyId: string): AxiosPromise<WrappedPropertyDetailsVm> => {
    return this.APIService.get(`property/get-details?propertyId=${propertyId}`);
  };

  /**
   * Replaces the list of appliances set for the property with the provided one.
   * Protected by Cookie authorization.
   */
  updateAppliances = (
    payload: UpdatePropertyAppliancesRequestVM,
  ): AxiosPromise<WrappedResponseVm> => {
    return this.APIService.post('property/update-appliances', payload);
  };

  /**
   * Get energy production for specific property.
   * Protected by Cookie authorization.
   */
  fetchProductionData = (
    propertyId: string,
    options: GetProductionDataOptions = {},
    cancellable = false,
  ): AxiosPromise<WrappedGetEnergyProductionResponseVm> => {
    let params;

    if (options.interval && options.startTimestamp) {
      options.startTimestamp = DateTime.fromISO(options.startTimestamp)
        .toUTC()
        .startOf('day')
        .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
    }

    if (!options.endTimestamp || DateTime.fromISO(options.endTimestamp).toUTC() > DateTime.utc()) {
      options.endTimestamp = DateTime.utc()
        .minus({ second: 5 })
        .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
    } else if (options.interval && options.endTimestamp) {
      options.endTimestamp = DateTime.fromISO(options.endTimestamp)
        .toUTC()
        .startOf('day')
        .minus({ second: 1 })
        .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
    }

    if (cancellable) {
      params = { ...options, PropertyId: propertyId };
    } else {
      const optionKeys = Object.keys(options) as ObjectKeys<GetProductionDataOptions>;

      params = optionKeys.reduce(
        (result, key) => `${result}&${key}=${options[key]}`,
        `PropertyId=${propertyId}`,
      );
    }

    return cancellable
      ? this.APIService.get('energy/get-production', { params })
      : this.APIService.get(`energy/get-production?${params}`);
  };
}

export const PropertyController = new PropertyControllerClass(Axios);
