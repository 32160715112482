import React, { ReactNode, useLayoutEffect, useState } from 'react';
import { Router as ReactRouterDomRouter } from 'react-router-dom';
import { History } from 'history';

type Props = {
  history: History;
  children: ReactNode;
};

export const Router = ({ history, children }: Props) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <ReactRouterDomRouter
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </ReactRouterDomRouter>
  );
};
